import styled from 'styled-components'
import tw from 'twin.macro'

const width = ({ $width }) => {
  if ($width !== 'full') return null

  return tw`w-full block`
}

const Base = styled.button`
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;

  ${({ disabled }) => disabled && tw`cursor-not-allowed`};
`

export const DefaultButton = styled(Base)`
  ${tw`inline-block py-3 sm:py-4 px-6 sm:px-8 border`};

  color: var(--color-white);
  background-color: var(--color-gold);
  border-color: var(--color-gold);

  &:hover {
    color: var(--color-gold);
    background-color: var(--color-white);
    border-color: var(--color-gold);
  }

  ${width}
`

export const OutlinedButton = styled(Base)`
  ${tw`inline-block py-3 sm:py-4 px-6 sm:px-8 border`};

  color: var(--color-gold);
  ${({ $backgroundColor = 'transparent' }) => $backgroundColor && { backgroundColor: $backgroundColor }};
  border-color: var(--color-gold);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-gold);
    border-color: var(--color-gold);
  }

  ${width}
`

export const InlineButton = styled(Base)`
  position: relative;
  padding: 0;
  border: none;
  color: var(--color-gold);
  background-color: transparent;
  white-space: nowrap;

  &::before {
    ${tw`absolute left-0 bottom-0`};
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--color-gold);
    transform-origin: top left;
    transform: scaleX(0);
    transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:hover::before {
    transform: scaleX(1);
  }

  ${width}
`

export const TextButton = styled.button`
  color: var(--color-gold);
  text-decoration: underline;
  cursor: pointer;
  ${tw`inline-block py-4`};
  ${width}
`
