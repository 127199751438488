import PropTypes from 'prop-types'

import Text from 'components/Text'

import { DefaultButton, InlineButton, OutlinedButton, TextButton } from './Button.style'

const componentForVariant = {
  default: DefaultButton,
  outlined: OutlinedButton,
  text: TextButton,
  inline: InlineButton,
}

const textPropsForVariant = {
  text: {},
}

const defaultTextProps = {
  font: 'proxima',
  size: 'sm',
  tracking: 'wide',
  transform: 'uppercase',
}

const Button = ({ text, variant, width, ...props }) => {
  const Component = componentForVariant[variant] || DefaultButton
  const textProps = textPropsForVariant[variant] || defaultTextProps

  return (
    <Component $width={width} {...props}>
      <Text {...textProps}>{text}</Text>
    </Component>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'outlined', 'text', 'inline']),
}

Button.defaultProps = {
  variant: 'default',
}

export default Button
