import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Header from 'components/Header'
import SubHeader from 'components/SubHeader'

import { Line, Root, TextWrapper } from './Divider.style'

const texts = {
  header: Header,
  subheader: SubHeader,
}

const Divider = ({ text, textProps, variant, ...props }) => {
  const Text = texts[variant]

  if (isPresent(text)) {
    return (
      <Root>
        <Line />
        <TextWrapper>
          <Text {...textProps}>{text}</Text>
        </TextWrapper>
        <Line />
      </Root>
    )
  }

  return <Line {...props} />
}

Divider.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(['subheader', 'header']),
}

Divider.defaultProps = {
  variant: 'subheader',
}

export default Divider
