import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-x-8`}
  grid-template-columns: 3fr 1fr;
`

export const Main = styled.div`
  ${tw`pl-8`};
  border-left: solid 1px var(--color-grey);
`

export const Collections = styled.div`
  ${tw`grid grid-cols-4 gap-x-8`}
`

export const LinkWrapper = styled.div`
  ${tw`block`};
`
