import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-md);
    margin-bottom: 1em;

    strong,
    b {
      font-weight: normal;
    }
  }

  h1 {
    ${tw`text-5xl`};
  }

  h2 {
    ${tw`text-4xl`};
  }

  h3 {
    ${tw`text-2xl`};
  }

  h4 {
    ${tw`text-xl`};
  }

  h5,
  h6 {
  }

  p + p {
    margin-top: 1em;
  }

  ul,
  ol {
    margin-bottom: 1em;
    padding-left: 0;

    li {
      list-style: none;
    }
  }

  strong,
  b {
    ${tw`font-semibold`};
  }

  em,
  i {
    ${tw`italic`};
  }

  a {
    ${tw`underline`};
    color: var(--color-gold);
  }
`
