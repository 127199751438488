import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-y-4`}
  color: var(--color-gold-black);
  grid-template-rows: min-content min-content auto;
`
export const NameAndAddress = styled.div``

export const Address = styled.div``

export const Name = styled.div`
  ${tw`mb-2`}
  color: var(--color-blue-grey);
`

export const EmailAndTelephoneNumber = styled.div`
  & > * {
    display: block;
  }
`
