import Image from 'next/image'
import PropTypes from 'prop-types'

import { formatMoney, isPresent } from 'utils'

import Header from 'components/Header'
import Spacer from 'components/Spacer'
import SubHeader from 'components/SubHeader'
import Text from 'components/Text'

import { BlurUp, CompareAtPrice, ImageWrapper, Main, Prices, Root, Title } from './ProductCardSmall.style'

const baseUrl = '/products/'

const blurUp = (src) => {
  return `${src}&w=100&blur=600&auto=format,compress`.replace(
    'cdn.shopify.com/s/files',
    'the-armoury-shopify.imgix.net'
  )
}

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}`.replace('cdn.shopify.com/s/files', 'the-armoury-shopify.imgix.net')
}

const ProductCardSmall = ({
  handle,
  variantId,
  imageUrl,
  artisan,
  title,
  price,
  compareAtPrice,
  fractionalPrices,
  children,
}) => {
  let href = null

  if (isPresent(handle) && isPresent(variantId)) {
    href = `${baseUrl}${handle}?variant=${variantId}`
  } else if (isPresent(handle)) {
    href = `${baseUrl}${handle}`
  }

  return (
    <Root as={href ? 'a' : 'div'} href={href}>
      {isPresent(imageUrl) && (
        <ImageWrapper>
          <BlurUp src={blurUp(imageUrl)} alt="" />
          <Image
            loader={loader}
            width="70"
            height="70"
            src={imageUrl}
            alt=""
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </ImageWrapper>
      )}
      <Main>
        <SubHeader>{artisan}</SubHeader>
        <Spacer y={1} />
        <Title>
          <Header size="xs">{title}</Header>
        </Title>
        <Spacer y={1} />
        <Prices>
          {isPresent(compareAtPrice) && (
            <CompareAtPrice>
              <Text>
                <s>{formatMoney(compareAtPrice, { fractional: fractionalPrices })}</s>
              </Text>
            </CompareAtPrice>
          )}
          <Text>{formatMoney(price, { fractional: fractionalPrices })}</Text>
        </Prices>
        {children}
      </Main>
    </Root>
  )
}

ProductCardSmall.propTypes = {
  handle: PropTypes.string,
  imageUrl: PropTypes.string,
  artisan: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  compareAtPrice: PropTypes.number,
}

ProductCardSmall.defaultProps = {
  fractionalPrices: true,
}

export default ProductCardSmall
