import PropTypes from 'prop-types'
import { useRecoilState } from 'recoil'

import { globalSearchOpenAtom } from 'state/atoms'

import SiteMenuItem from 'components/SiteMenuItem'
import SiteMenuItemGroup from 'components/SiteMenuItemGroup'
import SocialLinks from 'components/SocialLinks'
import Text from 'components/Text'

import { Item, Location, LocationTitle, Locations, Root, SearchItem } from './SiteMenu.style'

const SiteMenu = ({ primaryNavigation, shopNavigation, socialMedia }) => {
  const [_globalSearchOpen, setGlobalSearchOpen] = useRecoilState(globalSearchOpenAtom)

  return (
    <Root>
      <SearchItem onClick={() => setGlobalSearchOpen(true)}>
        <Text>Search for anything</Text>
      </SearchItem>
      <Item>
        <SiteMenuItemGroup text="Shop">
          {shopNavigation?.map((item, index) => (
            <SiteMenuItem key={index} {...item} />
          ))}
        </SiteMenuItemGroup>
      </Item>
      {primaryNavigation?.map((item, index) => (
        <Item key={index}>
          <SiteMenuItem {...item} />
        </Item>
      ))}
      <Locations>
        <Location>
          <LocationTitle>
            <Text font="ernestine" transform="uppercase">
              Hong Kong
            </Text>
          </LocationTitle>
          <SocialLinks
            tumblr={socialMedia.tumblr_hk}
            facebook={socialMedia.facebook_hk}
            twitter={socialMedia.twitter_hk}
            instagram={socialMedia.instagram_hk}
          />
        </Location>
        <Location>
          <LocationTitle>
            <Text font="ernestine" transform="uppercase">
              New York
            </Text>
          </LocationTitle>
          <SocialLinks
            tumblr={socialMedia.tumblr_ny}
            facebook={socialMedia.facebook_ny}
            twitter={socialMedia.twitter_ny}
            instagram={socialMedia.instagram_ny}
          />
        </Location>
      </Locations>
    </Root>
  )
}

SiteMenu.propTypes = {
  primaryNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ),
  shopNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ),
  socialMedia: PropTypes.shape({
    tumblr_hk: PropTypes.string,
    facebook_hk: PropTypes.string,
    twitter_hk: PropTypes.string,
    instagram_hk: PropTypes.string,
    tumblr_ny: PropTypes.string,
    facebook_ny: PropTypes.string,
    twitter_ny: PropTypes.string,
    instagram_ny: PropTypes.string,
  }),
}

SiteMenu.defaultProps = {}

export default SiteMenu
