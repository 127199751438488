import PropTypes from 'prop-types'

import { isBlank } from 'utils'

import Text from 'components/Text'

import { Root } from './SubHeader.style'

const SubHeader = ({ children, ...props }) => {
  if (isBlank(children) && isBlank(props.html)) return null

  return (
    <Root>
      <Text font="proxima" tracking="wide" transform="uppercase" {...props}>
        {children}
      </Text>
    </Root>
  )
}

SubHeader.propTypes = {
  /**
   * Text-like content
   * */
  children: PropTypes.node,
}

SubHeader.defaultProps = {}

export default SubHeader
