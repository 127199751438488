import styled from 'styled-components'

const sizes = {
  xxs: 1.25,
  xs: 1.375,
  sm: 1.75,
  base: 2.25,
  lg: 2.5,
  xl: 3.25,
  '2xl': 4.25,
  '3xl': 6.5,
}

const variants = {
  header: {},
  subheader: {
    textTransform: 'uppercase',
    fontSize: '0.813rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.025em',
  },
}

const fontSize = ({ $size }) => sizes[$size] && `font-size: ${sizes[$size]}rem;`
const variant = ({ $variant }) => variants[$variant]

export const Root = styled.div`
  ${fontSize};
  line-height: 1.18;
  /* ${variant}; */
  word-break: break-word;
`
