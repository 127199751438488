import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid grid-cols-2 gap-x-8`};
`

export const Column = styled.div`
  ${tw`grid gap-4 items-start`};
  grid-template-columns: 1fr 2fr;
`
