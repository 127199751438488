import * as prismicH from '@prismicio/helpers'

import isPresent from './isPresent'

const prismicAsLink = (linkField) => {
  // Replace absolute links with relative links if we're linking to our own site
  if (linkField?.link_type === 'Web' && /thearmoury\.com/.test(linkField.url)) {
    return new URL(linkField.url).pathname
  }

  // Fix bad relative URLs that are prefixed with https://
  // e.g. https:///artisans
  if (linkField?.link_type === 'Web' && /^https?:\/{3}/.test(linkField?.url)) {
    return linkField.url.replace(/https?:\/{2}/, '')
  }

  return isPresent(linkField) ? prismicH.asLink(linkField) : null
}

export default prismicAsLink
