import algoliasearch from 'algoliasearch/lite'
import { selector } from 'recoil'
import prismic, { predicate } from 'services/prismic'

import { isBlank, isPresent } from 'utils'
import fetchJSON from 'utils/fetchJSON'
import prismicAsLink from 'utils/prismicAsLink'

import prismicAnnouncementToAnnouncementProps from 'converters/prismicAnnouncementToAnnouncementProps'
import prismicTrunkShowToTrunkShowCardProps from 'converters/prismicTrunkShowToTrunkShowCardProps'

import { globalSearchQueryAtom } from './atoms'

const algolia = algoliasearch('DIVOOX7Z4L', 'ead999a12664cc933529dd4f786b17b4')

export const announcementsState = selector({
  key: 'announcements',
  get: async () => {
    let announcements = []

    try {
      announcements = await prismic.getAllByType('announcement')
    } catch (e) {} // eslint-disable-line no-empty

    return announcements.map(prismicAnnouncementToAnnouncementProps)
  },
})

export const trunkShowsState = selector({
  key: 'trunkShows',
  get: async () => {
    const response = await prismic.get({
      predicates: [
        predicate.at('document.type', 'trunk_show'),
        predicate.dateAfter(
          'my.trunk_show.end_date',
          new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]
        ),
      ],
      orderings: {
        field: 'my.trunk_show.start_date',
      },
      pageSize: 6,
    })

    return response.results.map(prismicTrunkShowToTrunkShowCardProps)
  },
})

export const topSearchResultsState = selector({
  key: 'topSearchResults',
  get: async ({ get }) => {
    const limit = 3
    const query = get(globalSearchQueryAtom)
    const results = {}

    if (isBlank(query)) return results

    try {
      const queries = [
        {
          indexName: 'tav3_products',
          query,
          params: {
            hitsPerPage: 9,
          },
        },
        {
          indexName: 'tav3_artisans',
          query,
          params: {
            hitsPerPage: limit,
          },
        },
        {
          indexName: 'tav3_collections',
          query,
          params: {
            hitsPerPage: limit,
          },
        },
        {
          indexName: 'tav3_articles',
          query,
          params: {
            hitsPerPage: limit,
          },
        },
      ]
      const response = await algolia.search(queries)

      for (const result of response.results) {
        results[result.index.replace('tav3_', '')] = result.hits
      }
    } catch (e) {
      console.error(e)
    } // eslint-disable-line no-empty

    return results
  },
})

export const searchResultsState = selector({
  key: 'searchResults',
  get: async ({ get }) => {
    const limit = 250
    const query = get(globalSearchQueryAtom)
    const results = {}

    if (isBlank(query)) return results

    try {
      const queries = [
        {
          indexName: 'tav3_products',
          query,
          params: {
            hitsPerPage: limit,
          },
        },
        {
          indexName: 'tav3_artisans',
          query,
          params: {
            hitsPerPage: limit,
          },
        },
        {
          indexName: 'tav3_collections',
          query,
          params: {
            hitsPerPage: limit,
          },
        },
        {
          indexName: 'tav3_articles',
          query,
          params: {
            hitsPerPage: limit,
          },
        },
      ]
      const response = await algolia.search(queries)

      for (const result of response.results) {
        results[result.index.replace('tav3_', '')] = result.hits
      }
    } catch (e) {
      console.error(e)
    } // eslint-disable-line no-empty

    return results
  },
})

export const popularArticlesState = selector({
  key: 'popularArticles',
  get: async () => {
    let articles = []

    try {
      const response = await fetchJSON(`${process.env.NEXT_PUBLIC_API_URI}/popular-articles`)
      articles = (
        await prismic.get({
          predicates: [predicate.in('my.article.uid', response)],
          fetchLinks: 'article_category.title',
        })
      ).results
    } catch (e) {} // eslint-disable-line no-empty

    return articles
  },
})

// const siteNavigation = ({ uid, data: { items } }) => [uid, items.map(siteNavigationItem)]

// const siteNavigationItem = ({ text, link, relative_link }) => {
//   const href = isPresent(relative_link) ? relative_link : prismicAsLink(link)

//   return { text, href }
// }

// export const siteNavigationState = selector({
//   key: 'siteNavigation',
//   get: async () => {
//     let navigations = []

//     try {
//       navigations = await prismic.getAllByType('navigation')
//     } catch (e) {} // eslint-disable-line no-empty

//     navigations = Object.fromEntries(navigations.map(siteNavigation))

//     return navigations
//   },
// })

// export const siteSubNavigationState = selector({
//   key: 'siteSubNavigation',
//   get: async () => {
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Artisans
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let artisans = []
//     let artisansNavigation = {}

//     try {
//       artisans = await prismic.getAllByType('artisan', {
//         orderings: {
//           field: 'my.artisan.name',
//           direction: 'asc',
//         },
//       })
//     } catch (e) {} // eslint-disable-line no-empty

//     try {
//       artisansNavigation = (await prismic.getSingle('navigation_artisans')).data
//     } catch (e) {} // eslint-disable-line no-empty

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Custom & Bespoke
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let customAndBespokeNavigation = {}

//     try {
//       customAndBespokeNavigation = (await prismic.getSingle('navigation_custom__bespoke')).data
//     } catch (e) {} // eslint-disable-line no-empty

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Journal
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let articles = []
//     let articleCategories = []

//     try {
//       // TODO: Rename this in Prismic
//       const journalNavigation = (await prismic.getSingle('navigation_editorial')).data

//       const articleIds = journalNavigation?.articles?.map((articleResult) => articleResult.article.id)

//       articles = (
//         await prismic.get({
//           predicates: [predicate.in('document.id', articleIds)],
//           fetch: ['article.title', 'article.cover_image', 'article.category', 'article.summary'],
//           fetchLinks: 'article_category.title',
//           pageSize: 4,
//         })
//       ).results

//       articleCategories = await prismic.getAllByType('article_category', {
//         orderings: {
//           field: 'my.article_category.title',
//           direction: 'asc',
//         },
//       })
//     } catch (e) {} // eslint-disable-line no-empty

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Contact
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let contactNavigation = {}
//     let locations = []

//     try {
//       contactNavigation = (await prismic.getSingle('navigation_contact')).data

//       locations = await prismic.getAllByType('location', {
//         orderings: {
//           field: 'my.location.position',
//         },
//       })
//     } catch (e) {} // eslint-disable-line no-empty

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // New arrivals
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let newArrivalsNavigation = {}

//     try {
//       newArrivalsNavigation = (await prismic.getSingle('navigation_new_arrivals')).data
//     } catch (e) {} // eslint-disable-line no-empty

//     let newArrivalsProducts = []

//     try {
//       const newArrivals = await fetchJSON(`${process.env.NEXT_PUBLIC_API_URI}/collections/new-arrivals?limit=2&facets=0`)
//       newArrivalsProducts = newArrivals?.collection?.products
//     } catch (e) {} // eslint-disable-line no-empty

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Clothing
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let clothingCategories = []
//     let clothingProducts = []

//     try {
//       clothingCategories = await fetchJSON(`${process.env.NEXT_PUBLIC_API_URI}/collections/?category=Clothing`)

//       const clothing = await fetchJSON(`${process.env.NEXT_PUBLIC_API_URI}/collections/clothing?limit=3&facets=0`)
//       clothingProducts = clothing?.collection?.products?.slice(0, 2)
//     } catch (e) {} // eslint-disable-line no-empty

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Accessories
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let accessoriesCategories = []
//     let accessoriesProducts = []

//     try {
//       accessoriesCategories = await fetchJSON(`${process.env.NEXT_PUBLIC_API_URI}/collections/?category=Accessories`)

//       const accessories = await fetchJSON(`${process.env.NEXT_PUBLIC_API_URI}/collections/accessories?limit=3&facets=0`)
//       accessoriesProducts = accessories?.collection?.products?.slice(0, 2)
//     } catch (e) {} // eslint-disable-line no-empty

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Footwear
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let footwearCategories = []
//     let footwearProducts = []

//     try {
//       footwearCategories = await fetchJSON(`${process.env.NEXT_PUBLIC_API_URI}/collections/?category=Footwear`)

//       const footwear = await fetchJSON(`${process.env.NEXT_PUBLIC_API_URI}/collections/footwear?limit=3&facets=0`)
//       footwearProducts = footwear?.collection?.products?.slice(0, 2)
//     } catch (e) {} // eslint-disable-line no-empty

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Collections
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//     let specialCollections = []

//     try {
//       specialCollections = (await prismic.getSingle('special_collections')).data?.collections
//     } catch (e) {} // eslint-disable-line no-empty

//     return {
//       artisans,
//       artisansNavigation,
//       customAndBespokeNavigation,
//       articles,
//       articleCategories,
//       contactNavigation,
//       locations,
//       newArrivalsNavigation,
//       newArrivalsProducts,
//       clothingCategories,
//       clothingProducts,
//       accessoriesCategories,
//       accessoriesProducts,
//       footwearCategories,
//       footwearProducts,
//       specialCollections,
//     }
//   },
// })
