import styled from 'styled-components'
import tw, { screen } from 'twin.macro'

export const Root = styled.div`
  ${tw`fixed z-50 top-0 left-0 w-full h-full grid gap-6 sm:gap-8 overflow-auto overscroll-contain`};
  grid-template-rows: min-content auto min-content;

  color: var(--color-text);
  background-color: var(--color-bg);

  overscroll-behavior: contain;
  pointer-events: auto;
`

export const Header = styled.div``

export const Main = styled.div`
  ${tw`overflow-auto overscroll-contain px-4 sm:px-8`};
`

export const Footer = styled.div`
  ${tw`grid sm:grid-cols-2 gap-y-4 gap-x-8 items-center px-4 sm:px-8 pb-4 sm:pb-8`};

  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);

  & > *:first-child {
    order: 1;
    ${screen`sm`({ order: 'initial' })};
  }
`
