import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div``

export const Announcement = styled.div`
  ${tw`block px-2 py-2 text-center`}
  color: var(--color-gold-on-gold-light-wcag);
  background-color: var(--color-gold-white);
  border-bottom: 0.5px solid var(--color-gold-lightest);
  text-decoration: ${({ href }) => (href ? 'underline' : 'none')};
`
