import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.form`
  ${tw`flex justify-between`};
  border-bottom: 1px solid var(--color-grey);
`

export const Input = styled.input.attrs((props) => ({ ...props, type: 'email' }))`
  ${tw`flex-grow`};
  padding: 1em 0;
  color: var(--color-gold-black);
  font-family: var(--font-nhu);

  &::placeholder {
    color: var(--color-grey-wcag);
    opacity: 1;
  }
`

export const Button = styled.button`
  color: var(--color-gold);
  font-family: var(--font-nhu);
  text-decoration: underline;
`

const StateMessage = styled.div`
  ${tw`select-none`};
  padding: 1em 0;
  color: var(--color-gold-black);
  font-family: var(--font-nhu);
  border-bottom: 1px solid transparent;
`

export const Submitting = styled(StateMessage)`
  cursor: wait;
`

export const Submitted = styled(StateMessage)``
