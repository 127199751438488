import { rem } from 'polished'
import styled from 'styled-components'
import tw, { screen } from 'twin.macro'

export const Root = styled.div`
  ${tw`pt-24 pb-4`};
  border-bottom: 1px solid var(--color-grey);
  border-bottom: 0.5rem solid var(--color-gold-light);
`

export const Locations = styled.div`
  ${tw`grid gap-x-14 md:gap-x-28 gap-y-12 px-insetX ta-max-width`};
  ${screen`sm`({ gridTemplateColumns: 'repeat(auto-fit, minmax(14rem, 1fr))' })};

  & > *:last-child {
    ${tw`order-first sm:order-last`};
  }
`

export const LocationsGroupWrapper = styled.div`
  position: relative;

  & + &::before {
    content: '';
    ${tw`hidden sm:block absolute top-0 left-0 w-px h-full -ml-7 md:-ml-14`};
    background-color: var(--color-grey);
  }
`

export const Newsletter = styled.div`
  ${tw`grid gap-y-3 px-insetX lg:px-0`};
  margin: 0 auto;
  width: 100%;
  max-width: ${rem(780)};
  grid-template-areas:
    'newsletter-header'
    'newsletter-blurb'
    'newsletter-form';

  @media (min-width: 46rem) {
    ${tw`grid-cols-2 gap-y-0 gap-x-24 place-content-around	`};
    grid-template-areas:
      'newsletter-header newsletter-blurb'
      'newsletter-form newsletter-blurb';
  }
`

// const NewsletterSection = styled.div`
//   @media (min-width: 40rem) {
//     width: 100%;
//     max-width: 20rem;
//     margin: 0 auto;
//   }
// `

// export const NewsletterHeader = styled(NewsletterSection)`
//   grid-area: newsletter-header;
// `
// export const NewsletterForm = styled(NewsletterSection)`
//   grid-area: newsletter-form;
// `
// export const NewsletterBlurb = styled(NewsletterSection)`
//   grid-area: newsletter-blurb;
// `

export const NewsletterHeader = styled.div`
  grid-area: newsletter-header;
`
export const NewsletterForm = styled.div`
  grid-area: newsletter-form;
  max-width: 24rem;
`
export const NewsletterBlurb = styled.div`
  ${tw`w-full max-w-prose`};
  grid-area: newsletter-blurb;
  color: var(--color-gold-black);
`

export const Navigation = styled.div`
  ${tw`flex justify-center flex-wrap`};
`

export const Legal = styled.div`
  ${tw`flex justify-center`};
  color: var(--color-blue-grey);
`

export const LogoWrapper = styled.div`
  ${tw`w-full max-w-[11rem] mx-auto my-4`};
`
