import NextLink from 'next/link'
import PropTypes from 'prop-types'

import Text from 'components/Text'

import { AnimatedRoot, Root } from './Link.style'

const Link = ({ text, href, animated, children, ...props }) => {
  const Component = animated ? AnimatedRoot : Root

  return (
    <NextLink href={href} passHref>
      <Component>
        <Text {...props}>{text || children}</Text>
      </Component>
    </NextLink>
  )
}

Link.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string.isRequired,
  animated: PropTypes.bool,
}

Link.defaultProps = {
  animated: false,
}

export default Link
