const formatProductSize = (size) => {
  if (/^xx-small$/i.test(size)) return 'XXS'
  if (/^x-small$/i.test(size)) return 'XS'
  if (/^small$/i.test(size)) return 'S'
  if (/^medium$/i.test(size)) return 'M'
  if (/^large$/i.test(size)) return 'L'
  if (/^x-large$/i.test(size)) return 'XL'
  if (/^xx-large$/i.test(size)) return 'XXL'
  if (/^os$/i.test(size)) return 'OS'

  return size
}

export default formatProductSize
