import Image from 'next/image'
import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Header from 'components/Header'
import Link from 'components/Link'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Column, Root } from './CustomAndBespokeNavigation.style'

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}&ar=6:4&fit=crop`
}

const CustomAndBespokeNavigation = ({ columns }) => {
  return (
    <Root>
      {columns?.map(({ imageUrl, header, body, cta, ctaLink }, index) => (
        <Column key={index}>
          {isPresent(imageUrl) && (
            <Image
              loader={loader}
              width="600"
              height="400"
              src={imageUrl}
              alt=""
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          )}
          <div>
            {isPresent(header) && (
              <>
                <Header size="xs">{header}</Header>
                <Spacer y={2} />
              </>
            )}
            {isPresent(body) && (
              <>
                <Text html={body} />
                <Spacer y={2} />
              </>
            )}
            {isPresent(cta) && isPresent(ctaLink) && <Link text={cta} href={ctaLink} animated />}
          </div>
        </Column>
      ))}
    </Root>
  )
}

CustomAndBespokeNavigation.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      header: PropTypes.string,
      body: PropTypes.string,
      cta: PropTypes.string,
      ctaLink: PropTypes.string,
    })
  ),
}

CustomAndBespokeNavigation.defaultProps = {}

export default CustomAndBespokeNavigation
