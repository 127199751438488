import { rem } from 'polished'
import styled from 'styled-components'

export const Root = styled.div`
  text-transform: uppercase;
  line-height: 1.25rem;
  letter-spacing: 0.15em;
  font-size: ${rem(13)};
  color: var(--color-blue-grey);
`
