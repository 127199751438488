import { rem } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`flex items-center cursor-pointer`};
`

export const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  /* We want the ellipsis to be in the correct font */
  font-family: var(--font-md);
  font-weight: 400;
  font-style: normal;
`

export const ImageWrapper = styled.div`
  ${tw`relative flex-none`};
  width: ${rem(70)};
  height: ${rem(70)};
`

export const Image = styled.img`
  ${tw`block w-full`};
`

export const Main = styled.div`
  ${tw`pl-4`};
`

export const Prices = styled.div`
  ${tw`flex flex-wrap`};
`

export const CompareAtPrice = styled.div`
  ${tw`mr-2`};
  color: var(--color-gold);
`

export const BlurUp = styled.img`
  ${tw`absolute top-0 left-0 w-full h-full`};
  z-index: -1;
`
