import styled from 'styled-components'
import tw, { screen } from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-x-8`};
  grid-template-columns: 1fr 1fr;

  /* ${screen`md`({ gridTemplateColumns: '1fr 3fr' })}; */
`

export const Products = styled.div`
  ${tw`grid xlnav:grid-cols-2 gap-8`};
`

export const Categories = styled.div`
  ${tw`pr-8`};
  border-right: solid 1px var(--color-grey);
`

export const CategoriesColumns = styled.div`
  column-count: 3;
  color: var(--color-gold-black);
`

export const Category = styled.div``
