import prismicAsLink from 'utils/prismicAsLink'

const prismicAnnouncementToAnnouncementProps = ({ data }) => {
  return {
    link: prismicAsLink(data.link),
    announcement: data.announcement,
  }
}

export default prismicAnnouncementToAnnouncementProps
