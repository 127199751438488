// -- Prismic Repo Name
export const repoName = 'the-armoury'

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = `https://${repoName}.cdn.prismic.io/api/v2`

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = ''

// -- Route Resolver rules
// Manages the url links to internal Prismic documents two levels deep (optionals)
export const Router = {
  routes: [
    {
      type: 'page',
      path: '/:uid',
    },
    {
      type: 'faqs_page',
      path: '/faq',
    },
    {
      type: 'artisan',
      path: '/artisans/:uid',
    },
    {
      type: 'article',
      path: '/journal/:uid',
    },
    {
      type: 'trunk_show',
      path: '/custom-bespoke/trunk-shows/:uid',
    },
  ],
}
