const fragments = `
  fragment CartFragment on Cart {
    id
    checkoutUrl
    buyerIdentity {
      countryCode
      customer {
        id
        email
        firstName
        lastName
        displayName
      }
      email
      phone
    }
    lines(first: $numCartLines) {
      edges {
        node {
          id
          quantity
          discountAllocations {
            discountedAmount {
              ...MoneyFragment
            }
          }
          estimatedCost {
            subtotalAmount {
              ...MoneyFragment
            }
            totalAmount {
              ...MoneyFragment
            }
          }
          attributes {
            key
            value
          }
          merchandise {
            ... on ProductVariant {
              id
              availableForSale
              compareAtPriceV2 {
                ...MoneyFragment
              }
              priceV2 {
                ...MoneyFragment
              }
              requiresShipping
              title
              image {
                ...ImageFragment
              }
              product {
                handle
                title
                vendor
                tags
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    estimatedCost {
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
    }
    note
    attributes {
      key
      value
    }
    discountCodes {
      code
      applicable
    }
  }

  fragment MoneyFragment on MoneyV2 {
    currencyCode
    amount
  }
  fragment ImageFragment on Image {
    id
    url
    altText
    width
    height
  }
`
export const CartQuery = `
  query CartQuery($id: ID!, $numCartLines: Int = 250) {
    cart(id: $id) {
      ...CartFragment
    }
  }

  ${fragments}
`

export const CartCreateMutation = `
  mutation cartCreate($numCartLines: Int = 250) {
    cartCreate(input: { buyerIdentity: { countryCode: US } }) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
    }
  }

  ${fragments}
`

export const CartLinesAddMutation = `
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!, $numCartLines: Int = 250) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
    }
  }

  ${fragments}
`

export const CartLinesUpdateMutation = `
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!, $numCartLines: Int = 250) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
    }
  }

  ${fragments}
`

export const CartLinesRemoveMutation = `
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!, $numCartLines: Int = 250) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
    }
  }

  ${fragments}
`

export const CartDiscountCodesUpdateMutation = `
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!], $numCartLines: Int = 250) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
    }
  }

  ${fragments}
`
