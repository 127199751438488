const collectionProductToProductCardSmallProps = (collectionProduct) => {
  const color = collectionProduct.options?.color

  return {
    key: collectionProduct._id,
    handle: collectionProduct.handle,
    variantId: collectionProduct.variant_id,
    imageUrl: collectionProduct.images?.[0] || null,
    altImageUrl: collectionProduct.images?.[1] || null,
    title: [collectionProduct.title, color].filter(Boolean).join(' - '),
    price: collectionProduct.price / 100,
    compareAtPrice: collectionProduct.compare_at_price,
    sizes: collectionProduct.size_variants?.map(({ size, inventory_quantity }) => ({
      size,
      available: inventory_quantity > 0,
    })),
    artisan: collectionProduct.named_tags?.displayBrand || collectionProduct.vendor,
  }
}

export default collectionProductToProductCardSmallProps
