import { rem } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div``

export const GroupText = styled.div`
  ${tw`relative flex items-center justify-between px-3 xlnav:px-4 whitespace-nowrap cursor-pointer select-none`}
  min-height: ${rem(45)};
  font-size: ${rem(13)};
  background-color: var(--color-bg);
  color: var(--color-gold-black);
`

export const GroupItems = styled.div`
  ${tw`ml-3`}
  border-top: solid 1px var(--color-grey);
`

export const ChevronIcon = styled.div`
  ${tw`transition transform rotate-0`}
  ${({ $isOpen }) => $isOpen && tw`rotate-180`}
`
