import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Button from 'components/Button'
import Header from 'components/Header'
import LocationCard from 'components/LocationCard'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Locations, Main, Root } from './ContactNavigation.style'

const ContactNavigation = ({ header, body, cta, ctaLink, locations }) => {
  return (
    <Root>
      <Locations>
        {locations?.map((props, index) => (
          <LocationCard key={index} {...props} />
        ))}
      </Locations>
      <Main>
        <Header size="xs">{header}</Header>
        <Spacer y={4} />
        <Text html={body} />
        <Spacer y={6} />
        {isPresent(cta) && isPresent(ctaLink) && (
          <Button as="a" text={cta} href={ctaLink} variant="outlined" width="full" />
        )}
      </Main>
    </Root>
  )
}

ContactNavigation.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  cta: PropTypes.string,
  ctaLink: PropTypes.string,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    })
  ),
}

ContactNavigation.defaultProps = {}

export default ContactNavigation
