import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Header from 'components/Header'
import RichText from 'components/RichText'
import Spacer from 'components/Spacer'

import { Excerpt, Image, Main, Root } from './ArticleCard.style'

const baseUrl = '/journal/'

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}&ar=6:4&fit=crop`
}

const ArticleCard = ({ layout, handle, imageUrl, category: _category, title, excerpt }) => {
  return (
    <Root as={isPresent(handle) ? 'a' : 'div'} href={isPresent(handle) && `${baseUrl}${handle}`} $layout={layout}>
      {isPresent(imageUrl) && <Image loader={loader} width="600" height="400" src={imageUrl} alt="" />}
      <Main>
        <Header size="xs">{title}</Header>
        <Spacer y={2} />
        <Excerpt>
          <RichText html={excerpt} />
        </Excerpt>
      </Main>
    </Root>
  )
}

ArticleCard.propTypes = {
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  handle: PropTypes.string,
  imageUrl: PropTypes.string,
  // category: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
}

ArticleCard.defaultProps = {
  layout: 'vertical',
}

export default ArticleCard
