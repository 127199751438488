import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-x-8`};
  grid-template-columns: 3fr 1fr;
`

export const Articles = styled.div`
  ${tw`grid grid-cols-2 lg:grid-cols-4 gap-x-8 items-start`};

  & > *:nth-child(n + 3) {
    ${tw`hidden lg:block`}
  }
`

export const Article = styled.div``

export const Categories = styled.div`
  ${tw`pl-8`};
  border-left: solid 1px var(--color-grey);
`

export const Category = styled.div``
