import Link from 'next/link'
import PropTypes from 'prop-types'

import { isBlank } from 'utils'

import Text from 'components/Text'

import { Active, Inactive, Root } from './SiteMenuItem.style'

const SiteMenuItem = ({ text, href, active }) => {
  if (isBlank(text)) return null

  const Content = active ? Active : Inactive

  return (
    <Link href={href} passHref legacyBehavior>
      <Root>
        <Content>
          <Text font="proxima" tracking="wide" transform="uppercase">
            {text}
          </Text>
        </Content>
      </Root>
    </Link>
  )
}

SiteMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
}

SiteMenuItem.defaultProps = {
  active: false,
}

export default SiteMenuItem
