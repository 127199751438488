import PropTypes from 'prop-types'
import { useRef } from 'react'
import 'twin.macro'

import locationGroups from 'data/locationGroups.json'
import siteNavigation from 'data/siteNavigation.json'

import Divider from 'components/Divider'
import Header from 'components/Header'
import LocationsGroup from 'components/LocationsGroup'
import NewsletterSubscribeForm from 'components/NewsletterSubscribeForm'
import SiteNavigationItem from 'components/SiteNavigationItem'
import Text from 'components/Text'

import {
  Legal,
  Locations,
  LocationsGroupWrapper,
  LogoWrapper,
  Navigation,
  Newsletter,
  NewsletterBlurb,
  NewsletterForm,
  NewsletterHeader,
  Root,
} from './SiteFooter.style'
import Logo from './svg/logo.svg'

const { footer: navigation } = siteNavigation

const SiteFooter = () => {
  const year = useRef(new Date().getFullYear())

  return (
    <Root>
      <Locations>
        {locationGroups?.map((locationGroup) => (
          <LocationsGroupWrapper key={locationGroup.title}>
            <LocationsGroup {...locationGroup} />
          </LocationsGroupWrapper>
        ))}
      </Locations>
      <Divider tw="my-6 md:my-12 mx-insetX" />
      <Newsletter>
        <NewsletterHeader>
          <Header size="sm">Subscribe to Our Newsletter</Header>
        </NewsletterHeader>
        <NewsletterForm>
          <NewsletterSubscribeForm />
        </NewsletterForm>
        <NewsletterBlurb>
          <Text>
            Keep up to date with the latest new arrivals, styling tips, promotions and roundups of our best editorial
            features.
          </Text>
        </NewsletterBlurb>
      </Newsletter>
      <Divider tw="mt-6 md:mt-12" />
      <Navigation>
        {/* <SiteNavigation items={navigation} /> */}
        {navigation?.map((item, index) => (
          <SiteNavigationItem key={index} {...item} showHoverIndicator={false} />
        ))}
      </Navigation>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Legal>
        <Text size="sm">© {year.current} The Armoury. All rights reserved.</Text>
      </Legal>
    </Root>
  )
}

SiteFooter.propTypes = {
  locationGroups: PropTypes.arrayOf(PropTypes.object),
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}

SiteFooter.defaultProps = {}

export default SiteFooter
