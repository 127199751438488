import PropTypes from 'prop-types'

import Link from 'components/Link'
import ProductCardMedium from 'components/ProductCardMedium'
import Spacer from 'components/Spacer'

import { Categories, CategoriesColumns, Category, Products, Root } from './AccessoriesNavigation.style'

const AccessoriesNavigation = ({ categories, products }) => {
  return (
    <Root>
      <Categories>
        <Link text="All Accessories" href="/collections/accessories" animated weight="semibold" />
        <Spacer y={1} />
        <CategoriesColumns>
          {categories?.map(({ title, handle }, index) => (
            <Category key={index}>
              <Link text={title} href={`/collections/${handle}`} animated />
            </Category>
          ))}
        </CategoriesColumns>
      </Categories>
      <Products>
        {products?.map((product, index) => (
          <ProductCardMedium key={index} {...product} baseUrl="/collections/accessories/" />
        ))}
      </Products>
    </Root>
  )
}

AccessoriesNavigation.propTypes = {
  categories: PropTypes.array,
  products: PropTypes.array,
}

AccessoriesNavigation.defaultProps = {}

export default AccessoriesNavigation
