import { partition } from 'ramda'
import naturalCompare from 'string-natural-compare'

const sortedSizes = ['OS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']

const formattedSize = (size) => {
  if (/^xx-small$/i.test(size)) return 'XXS'
  if (/^x-small$/i.test(size)) return 'XS'
  if (/^small$/i.test(size)) return 'S'
  if (/^medium$/i.test(size)) return 'M'
  if (/^large$/i.test(size)) return 'L'
  if (/^x-large$/i.test(size)) return 'XL'
  if (/^xx-large$/i.test(size)) return 'XXL'
  if (/^os$/i.test(size)) return 'OS'

  return null
}

const sortProductSizes = (values) => {
  let [strings, numbers] = partition(isNaN)(values)

  strings = strings.sort((a, b) => sortedSizes.indexOf(formattedSize(a)) - sortedSizes.indexOf(formattedSize(b)))

  numbers = numbers.sort(naturalCompare)

  return [...strings, ...numbers]
}

export default sortProductSizes
