import PropTypes from 'prop-types'

import { isBlank } from 'utils'

import Text from 'components/Text'

import { Root } from './RichText.style'

const RichText = ({ html, ...props }) => {
  if (isBlank(html)) return null

  return (
    <Root>
      <Text html={html} {...props} />
    </Root>
  )
}

RichText.propTypes = {
  html: PropTypes.string,
}

RichText.defaultProps = {}

export default RichText
