import PropTypes from 'prop-types'
import { useRecoilValueLoadable } from 'recoil'

import { isBlank, isPresent } from 'utils'

import { announcementsState } from 'state/selectors'

import Text from 'components/Text'

import { Announcement, Root } from './Announcements.style'

// eslint-disable-next-line no-unused-vars
const Announcements = ({ countryCode = 'US' }) => {
  const announcementsLoadable = useRecoilValueLoadable(announcementsState)
  const announcements = announcementsLoadable.state === 'hasValue' ? announcementsLoadable.contents : []

  if (isBlank(announcements)) return null

  return (
    <Root>
      {announcements.map(({ link, announcement }, index) => (
        <Announcement key={index} as={isPresent(link) ? 'a' : 'div'} href={link}>
          <Text>{announcement}</Text>
        </Announcement>
      ))}
    </Root>
  )
}

Announcements.propTypes = {
  announcements: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      announcement: PropTypes.string.isRequired,
      countryCodes: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  countryCode: PropTypes.string,
}

Announcements.defaultProps = {}

export default Announcements
