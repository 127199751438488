import PropTypes from 'prop-types'
import BalanceText from 'react-balance-text'

import { Root } from './Text.style'

const Text = ({ font, size, tracking, transform, weight, style, html, balance, prose, color, children, ...props }) => {
  if (html)
    return (
      <Root
        {...props}
        as={prose ? 'div' : props.as || 'span'}
        $font={font}
        $size={size}
        $tracking={tracking}
        $transform={transform}
        $weight={weight}
        $style={style}
        $prose={prose}
        $color={color}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )

  return (
    <Root
      {...props}
      as={prose ? 'div' : props.as || 'span'}
      $font={font}
      $size={size}
      $tracking={tracking}
      $transform={transform}
      $weight={weight}
      $style={style}
      $prose={prose}
      $color={color}
    >
      {balance ? <BalanceText>{children}</BalanceText> : children}
    </Root>
  )
}

Text.propTypes = {
  font: PropTypes.oneOf(['unica', 'freight', 'proxima', 'stint', 'ernestine']),
  /**
   * Font size
   * */
  size: PropTypes.oneOf([
    'inherit',
    'xs',
    'sm',
    'base',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
    '8xl',
    '9xl',
  ]),
  tracking: PropTypes.oneOf(['normal', 'wide']),
  transform: PropTypes.oneOf(['none', 'uppercase']),
  weight: PropTypes.oneOf(['normal', 'semibold', 'bold']),
  style: PropTypes.oneOf(['normal', 'italic']),
  balance: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
}

Text.defaultProps = {
  font: 'unica',
  size: null,
  tracking: 'normal',
  transform: 'none',
  weight: 'normal',
  style: 'normal',
  balance: false,
  color: null,
  children: null,
}

export default Text
