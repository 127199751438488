import * as prismicH from '@prismicio/helpers'

const prismicTrunkShowToTrunkShowCardProps = ({ uid, data }) => {
  return {
    handle: uid,
    imageUrl: data.cover_image?.url,
    locationBadge: data.location_tag,
    title: data.title,
    location: data.location,
    startDate: data.start_date,
    endDate: data.end_date,
    summary: prismicH.asHTML(data.summary),
  }
}

export default prismicTrunkShowToTrunkShowCardProps
