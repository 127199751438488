import PropTypes from 'prop-types'

import { Root, SocialLink } from './SocialLinks.style'
import Facebook from './svg/facebook.svg'
import Instagram from './svg/instagram.svg'
import Tumblr from './svg/tumblr.svg'
import Twitter from './svg/twitter.svg'

const SocialLinks = ({ tumblr, facebook, twitter, instagram }) => {
  return (
    <Root>
      {tumblr && (
        <SocialLink href={tumblr}>
          <Tumblr width={10} height={16} />
        </SocialLink>
      )}
      {facebook && (
        <SocialLink href={facebook}>
          <Facebook width={9} height={18} />
        </SocialLink>
      )}
      {twitter && (
        <SocialLink href={twitter}>
          <Twitter width={18} height={15} />
        </SocialLink>
      )}
      {instagram && (
        <SocialLink href={instagram}>
          <Instagram width={18} height={18} />
        </SocialLink>
      )}
    </Root>
  )
}

SocialLinks.propTypes = {
  tumblr: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
}

SocialLinks.defaultProps = {}

export default SocialLinks
