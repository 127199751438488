import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'

import { isBlank } from 'utils'

import { globalSearchOpenAtom, globalSearchQueryAtom } from 'state/atoms'

import Button from 'components/Button'
import SearchInput from 'components/SearchInput'
import SearchResults from 'components/SearchResults'

import { Footer, Header, Main, Root } from './SearchModal.style'

const SearchModal = () => {
  const router = useRouter()
  const [globalSearchOpen, setGlobalSearchOpen] = useRecoilState(globalSearchOpenAtom)
  const [globalSearchQuery, setGlobalSearchQuery] = useRecoilState(globalSearchQueryAtom)

  const navParams = {
    pathname: '/search',
    query: { q: globalSearchQuery },
  }

  const viewAllResults = () => router.push(navParams)

  if (!globalSearchOpen) return null

  return (
    <Root>
      <Header>
        <SearchInput isOpen inputProps={{ autoFocus: true }} onSubmit={viewAllResults} />
      </Header>
      <Main>
        <SearchResults />
      </Main>
      <Footer>
        <Button
          text="Close"
          variant="outlined"
          onClick={() => {
            setGlobalSearchOpen(false)
            setGlobalSearchQuery('')
          }}
        />
        <Link href={navParams} passHref legacyBehavior>
          <Button as="a" text="View all results" width="full" disabled={isBlank(globalSearchQuery)} />
        </Link>
      </Footer>
    </Root>
  )
}

export default SearchModal
