import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Description, Main, Root } from './CollectionCard.style'

const baseUrl = '/collections'

// TODO: Loading these from Imgix because they don't exist in the AWS bucket
const loader = ({ src, width, quality }) => {
  return `${src}?w=${width}&q=${quality || 75}&ar=6:4&fit=crop`
    .replace('s3.amazonaws.com/armoury-storage-production', 'the-armoury-s3.imgix.net')
    .replace('armoury-collections-cms.s3.amazonaws.com', 'the-armoury-s3.imgix.net')
}

const CollectionCard = ({ title, description, handle, image, reverse, theme }) => {
  return (
    <Link href={`${baseUrl}/${handle}`}>
      <Root $reverse={reverse}>
        <Image
          src={image}
          alt={title}
          width={600}
          height={400}
          loader={loader}
          sizes="100vw"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
        <Spacer y={4} />
        <Main $reverse={reverse} $theme={theme}>
          <Header size="xs">{title}</Header>
          <Spacer y={2} />
          <Description>
            <Text>{description}</Text>
          </Description>
        </Main>
      </Root>
    </Link>
  )
}

CollectionCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  handle: PropTypes.string,
  image: PropTypes.string,
  reverse: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
}

CollectionCard.defaultProps = {
  reverse: false,
}

export default CollectionCard
