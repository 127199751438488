import PropTypes from 'prop-types'

import SiteNavigationItem from 'components/SiteNavigationItem'

import { Item, Root } from './SiteNavigation.style'

const SiteNavigation = ({ items = [] }) => {
  return (
    <Root>
      {items.map((item, index) => (
        <Item key={index}>
          <SiteNavigationItem {...item} />
        </Item>
      ))}
    </Root>
  )
}

SiteNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}

SiteNavigation.defaultProps = {}

export default SiteNavigation
