import { Burger } from '@mantine/core'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useRecoilState } from 'recoil'
import 'twin.macro'

import { useCart, useRoute } from 'hooks'

import { globalSearchOpenAtom, smallScreenNavigationIsOpenAtom } from 'state/atoms'

import siteNavigation from 'data/siteNavigation.json'
import socialMedia from 'data/socialMedia.json'

import SiteMenu from 'components/SiteMenu'
import SiteNavigation from 'components/SiteNavigation'
import Text from 'components/Text'

import {
  AccountArea,
  AccountIcon,
  AccountItem,
  CartArea,
  CartCount,
  CartIcon,
  CartItem,
  CartItemActive,
  EssentialNavArea,
  HamburgerArea,
  HrBottom,
  HrMiddle,
  HrTop,
  Logo,
  LogoArea,
  LogoComplex,
  Main,
  PrimaryNavArea,
  Root,
  SearchArea,
  SearchIcon,
  SearchItem,
  SecondaryNavArea,
  SmallScreenNav,
  SmallScreenNavInner,
} from './SiteHeader.style'

const siteMenuArgs = {
  primaryNavigation: siteNavigation['primary-compact'],
  shopNavigation: siteNavigation.shop,
  socialMedia,
}

const primaryNavigation = [...siteNavigation.primary, { text: 'Account', href: '/account' }]

const SiteHeader = () => {
  const route = useRoute()
  const { count } = useCart()
  const CartItemComponent = route === '/cart' ? CartItemActive : CartItem

  const [smallScreenNavigationIsOpen, setSmallScreenNavigationIsOpen] = useRecoilState(smallScreenNavigationIsOpenAtom)

  const handleHamburgerClick = () => {
    const willBeOpen = !smallScreenNavigationIsOpen
    setSmallScreenNavigationIsOpen(willBeOpen)
  }

  const [_globalSearchOpen, setGlobalSearchOpen] = useRecoilState(globalSearchOpenAtom)

  return (
    <>
      <Root>
        <Main>
          <HamburgerArea>
            <Burger size={17} opened={smallScreenNavigationIsOpen} onClick={handleHamburgerClick} />
          </HamburgerArea>
          <LogoArea>
            <Link href="/">
              <Logo width={200} height={25.69} />
              <LogoComplex width={344} height={69} />
            </Link>
          </LogoArea>
          <EssentialNavArea>
            <SiteNavigation items={siteNavigation.essential} />
          </EssentialNavArea>
          <PrimaryNavArea>
            <SiteNavigation items={primaryNavigation} />
          </PrimaryNavArea>
          <SecondaryNavArea>
            <SiteNavigation items={siteNavigation.secondary} />
          </SecondaryNavArea>
          <AccountArea>
            <Link href="/account" passHref legacyBehavior>
              <AccountItem>
                <AccountIcon />
              </AccountItem>
            </Link>
          </AccountArea>
          <SearchArea>
            <SearchItem onClick={() => setGlobalSearchOpen(true)}>
              <SearchIcon />
            </SearchItem>
          </SearchArea>
          <CartArea>
            <Link href="/cart" passHref legacyBehavior>
              <CartItemComponent>
                <CartIcon />
                {count > 0 && (
                  <CartCount>
                    <Text font="ernestine" weight="semibold" size="sm">
                      {count}
                    </Text>
                  </CartCount>
                )}
              </CartItemComponent>
            </Link>
          </CartArea>
          <HrTop />
          <HrMiddle />
          <HrBottom />
          <SmallScreenNav $isOpen={smallScreenNavigationIsOpen}>
            <SmallScreenNavInner>
              <SiteMenu {...siteMenuArgs} />
            </SmallScreenNavInner>
          </SmallScreenNav>
        </Main>
      </Root>
    </>
  )
}

SiteHeader.propTypes = {
  essentialNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  primaryNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  secondaryNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}

SiteHeader.defaultProps = {}

export default SiteHeader
