import Image from 'next/image'

import { isBlank, isPresent } from 'utils'

import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Email, ImageWrapper, LocationBadgeX, LocationBadgeY, Root, StaticLocationBadge } from './LocationCard.style'
import HKx from './svg/hk-x.svg'
import HKy from './svg/hk-y.svg'
import NYx from './svg/ny-x.svg'
import NYy from './svg/ny-y.svg'
import SHx from './svg/sh-x.svg'
import SHy from './svg/sh-y.svg'

const locationBadges = {
  HK: [HKx, HKy],
  SH: [SHx, SHy],
  NY: [NYx, NYy],
}

const LocationCard = ({ imageUrl, name, address, locationBadge, email, telephone, openingHours }) => {
  const [badgeX, badgeY] = locationBadges[locationBadge] || []

  return (
    <Root>
      {isPresent(imageUrl) && (
        <>
          <ImageWrapper>
            <Image
              src={imageUrl}
              width={600}
              height={400}
              alt=""
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
            {badgeX && badgeY && (
              <>
                <LocationBadgeX>{badgeX({ viewBox: '0 0 437.6 249.5' })}</LocationBadgeX>
                <LocationBadgeY>{badgeY({ viewBox: '0 0 249.5 437.6' })}</LocationBadgeY>
              </>
            )}
          </ImageWrapper>
          <Spacer y={12} />
        </>
      )}
      <Text weight="bold">{name}</Text>
      <Text html={address} />
      {(isPresent(email) || isPresent(telephone)) && <Spacer y={4} />}
      {isPresent(email) && (
        <Email>
          <a href={`mailto:${email}`}>
            <Text>{email}</Text>
          </a>
        </Email>
      )}
      {isPresent(telephone) && (
        <div>
          <a href={`mailto:${telephone}`}>
            <Text>{telephone}</Text>
          </a>
        </div>
      )}
      {isPresent(openingHours) && (
        <>
          <Spacer y={4} />
          <Text html={openingHours} />
        </>
      )}
      {isBlank(imageUrl) && badgeX && (
        <>
          <Spacer y={4} />
          <StaticLocationBadge>{badgeX({ viewBox: '0 0 437.6 249.5' })}</StaticLocationBadge>
        </>
      )}
    </Root>
  )
}

LocationCard.propTypes = {}

LocationCard.defaultProps = {}

export default LocationCard
