import * as prismic from '@prismicio/client'
import { enableAutoPreviews } from '@prismicio/next'
import { Router } from 'config/prismic'
import { articleGraphQuery, pageGraphQuery } from 'config/prismicGraphQueries'

const endpoint = prismic.getEndpoint('the-armoury')
const client = prismic.createClient(endpoint, {
  routes: Router.routes,
})

export default client

export const predicate = prismic.predicate

const fixUpPrismicDocument = async (document) => {
  // eslint-disable-next-line no-undef
  document.data.body = await Promise.all(
    document.data.body.map(async (slice) => {
      if (slice.slice_type !== 'products') return slice

      // eslint-disable-next-line no-undef
      const newItems = await Promise.all(
        slice.items.map(async ({ product }) => {
          if (!product) return null

          const { handle } = product
          const productData = await (await fetch(`${process.env.API_URI}/products/${handle}`)).json()

          if (!productData) return null

          return {
            product: {
              ...product,
              size_variants: productData.size_variants,
              images: productData.images,
              images_by_color: productData.images_by_color || null,
            },
          }
        })
      )

      slice.items = newItems.filter(Boolean)

      return slice
    })
  )

  return document
}

export const createClient = (config = {}) => {
  const client = prismic.createClient(endpoint, {
    routes: [
      {
        type: 'page',
        path: '/:uid',
      },
      {
        type: 'faqs_page',
        path: '/faq',
      },
      {
        type: 'artisan',
        path: '/artisans/:uid',
      },
      {
        type: 'article',
        path: '/journal/:uid',
      },
      {
        type: 'trunk_show',
        path: '/custom-bespoke/trunk-shows/:uid',
      },
    ],
  })

  enableAutoPreviews({ client, previewData: config.previewData, req: config.req })

  client.getPage = async (uid) => {
    const document = await client.getByUID('page', uid, { graphQuery: pageGraphQuery })

    return fixUpPrismicDocument(document)
  }

  client.getArticle = async (uid) => {
    const document = await client.getByUID('article', uid, {
      fetchLinks: 'article_category.title',
      graphQuery: articleGraphQuery,
    })

    return fixUpPrismicDocument(document)
  }

  return client
}
