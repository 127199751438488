import PropTypes from 'prop-types'

import Link from 'components/Link'
import Spacer from 'components/Spacer'

import ArticleCard from './ArticleCard'
import { Article, Articles, Categories, Category, Root } from './JournalNavigation.style'

const JournalNavigation = ({ articles, articleCategories }) => {
  return (
    <Root>
      <Articles>
        {articles?.map((article, index) => (
          <Article key={index}>
            <ArticleCard {...article} />
          </Article>
        ))}
      </Articles>
      <Categories>
        <Link text="All Articles" href="/journal" animated weight="semibold" />
        <Spacer y={1} />
        {articleCategories?.map(({ handle, title }) => (
          <Category key={handle}>
            <Link text={title} href={`/journal/sections/${handle}`} animated />
          </Category>
        ))}
      </Categories>
    </Root>
  )
}

JournalNavigation.propTypes = {
  articles: PropTypes.array,
  articleCategories: PropTypes.array,
}

JournalNavigation.defaultProps = {}

export default JournalNavigation
