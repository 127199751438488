import currency from 'currency.js'

const formatMoney = (
  value = 0,
  options = {
    fractional: true,
  }
) => {
  try {
    return currency(value, { fromCents: options.fractional, symbol: 'USD ' }).format().replace(/\.00$/, '')
  } catch (e) {
    console.error(e)
    return value
  }
}

export default formatMoney
