import { CustomerQuery } from 'gql/customer'
import { pick } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useQuery } from 'urql'

import { isBlank } from 'utils'
import shopifyGidToId from 'utils/shopifyGidToId'

import { customerAccessTokenAtom } from 'state/atoms'

const useCustomer = () => {
  const [loading, setLoading] = useState(true)
  const customerAccessToken = useRecoilValue(customerAccessTokenAtom)

  const [result] = useQuery({
    query: CustomerQuery,
    variables: { customerAccessToken },
    pause: isBlank(customerAccessToken),
  })

  const customerResult = result?.data?.customer

  const data = useMemo(
    () =>
      customerResult
        ? {
            ...pick(['email', 'displayName'], customerResult),
            gid: customerResult.id,
            id: shopifyGidToId(customerResult.id),
          }
        : null,
    [customerResult]
  )

  useEffect(() => {
    if (result.fetching) return

    setLoading(false)
  }, [result])

  return { data, loading }
}

export default useCustomer
