import styled from 'styled-components'

export const Root = styled.div``

export const Main = styled.div``

export const Description = styled.div`
  [class^='Text'] {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
