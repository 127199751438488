import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'

import { isPresent } from 'utils'

import { globalSearchQueryAtom } from 'state/atoms'

// import { useClickOutside } from 'hooks'
import Text from 'components/Text'

import {
  CloseIcon,
  Input,
  InputClose,
  InputWrapper,
  Placeholder,
  PlaceholderIcon,
  PlaceholderText,
  Root,
} from './SearchInput.style'

const SearchInput = ({ onFocus, onBlur, onSubmit, isOpen, inputProps = {} }) => {
  const rootRef = useRef()
  const inputRef = useRef()

  const [globalSearchQuery, setGlobalSearchQuery] = useRecoilState(globalSearchQueryAtom)

  const [focussed, setFocussed] = useState(isPresent(globalSearchQuery))

  const close = useCallback(() => {
    setFocussed(false)
    setGlobalSearchQuery && setGlobalSearchQuery('')
    onBlur()
  }, [setFocussed, setGlobalSearchQuery, onBlur])

  const handlePlaceholderClick = () => {
    setFocussed(true)
    onFocus()
  }

  const handleInputCloseClick = () => {
    close()
  }

  // useClickOutside(rootRef, () => {
  //   close()
  // })

  const handleKeyUp = (e) => {
    if (e.key !== 'Enter') return

    onSubmit()
  }

  useEffect(() => {
    const handleKeydown = (e) => {
      if (isOpen || !focussed) return

      if (e.key === 'Escape') close()
    }

    document.addEventListener('keydown', handleKeydown, false)

    return () => {
      document.removeEventListener('keydown', handleKeydown, false)
    }
  }, [close, focussed, isOpen])

  useEffect(() => {
    // focussed ? inputRef.current.focus() : inputRef.current.blur()
    if (focussed) inputRef.current.focus()
  }, [focussed])

  // useEffect(() => {
  //   focussed ? onFocus() : onBlur()
  // }, [focussed, onFocus, onBlur])

  return (
    <Root ref={rootRef} $focussed={isOpen || focussed}>
      <Placeholder onClick={handlePlaceholderClick}>
        <PlaceholderText>
          <Text font="proxima" tracking="wide" transform="uppercase">
            Search
          </Text>
        </PlaceholderText>
        <PlaceholderIcon width={18} height={17} />
      </Placeholder>
      <InputWrapper $focussed={isOpen || focussed}>
        {/* <Input ref={inputRef} type="text" placeholder="Search for a product, artisan or article" /> */}
        <Input
          ref={inputRef}
          type="text"
          placeholder="Search for anything"
          value={globalSearchQuery || ''}
          onChange={(e) => setGlobalSearchQuery(e.target.value)}
          onKeyUp={handleKeyUp}
          {...inputProps}
        />
        {!isOpen && (
          <InputClose onClick={handleInputCloseClick}>
            <CloseIcon width={18} height={17} />
          </InputClose>
        )}
      </InputWrapper>
    </Root>
  )
}

SearchInput.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isOpen: PropTypes.bool,
}

SearchInput.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  isOpen: false,
}

export default SearchInput
