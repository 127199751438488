import NextImage from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import { isPresent } from 'utils'

import Header from 'components/Header'
import Spacer from 'components/Spacer'
import SubHeader from 'components/SubHeader'

import { AltImage, ImageContainer, ImageWrapper, Main, PrimaryImage, Root, Title } from './ProductCardMedium.style'

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}&auto=format,compress`.replace(
    'cdn.shopify.com/s/files',
    'the-armoury-shopify.imgix.net'
  )
}

const ProductCardMedium = ({
  handle,
  variantId,
  imageUrl,
  altImageUrl,
  artisan,
  title,
  price: _price,
  compareAtPrice: _compareAtPrice,
  baseUrl = '/products/',
}) => {
  const PossibleLink = isPresent(handle) ? Link : Fragment

  let href = null

  if (isPresent(handle) && isPresent(variantId)) {
    href = `${baseUrl}${handle}?variant=${variantId}`
  } else if (isPresent(handle)) {
    href = `${baseUrl}${handle}`
  }

  return (
    <Root>
      <ImageWrapper>
        <PossibleLink href={href} passHref>
          <ImageContainer>
            <PrimaryImage>
              <NextImage
                loader={loader}
                src={imageUrl}
                width={400}
                height={400}
                alt=""
                sizes="(min-width: 48rem) calc(14rem - 33.33w), (min-width: 36rem) calc(14rem - 50w), calc(14rem - 100w)"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </PrimaryImage>
            {isPresent(altImageUrl) && (
              <AltImage>
                <NextImage
                  loader={loader}
                  src={altImageUrl}
                  width={400}
                  height={400}
                  alt=""
                  sizes="(min-width: 48rem) calc(14rem - 33.33w), (min-width: 36rem) calc(14rem - 50w), calc(14rem - 100w)"
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </AltImage>
            )}
          </ImageContainer>
        </PossibleLink>
      </ImageWrapper>
      <PossibleLink href={href} passHref>
        <Main>
          <SubHeader>{artisan}</SubHeader>
          <Spacer y={1} />
          <Title>
            <Header size="xs">{title}</Header>
          </Title>
          <Spacer y={1} />
        </Main>
      </PossibleLink>
    </Root>
  )
}

ProductCardMedium.propTypes = {
  handle: PropTypes.string,
  variantId: PropTypes.string,
  imageUrl: PropTypes.string,
  artisan: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  compareAtPrice: PropTypes.number,
}

ProductCardMedium.defaultProps = {}

export default ProductCardMedium
