import styled from 'styled-components'
import tw, { screen } from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-x-8 items-start`};
  ${screen`md`({ gridTemplateColumns: '1fr 3fr' })};
`

export const ContentResults = styled.div`
  ${tw`grid grid-cols-1 sm:grid-cols-3 md:grid-cols-1 gap-x-4 gap-y-2 pb-6 mb-6`};
  border-bottom: solid 0.5px var(--color-grey);

  ${screen`md`(tw`pb-0 mb-0 border-b-0 pr-8`)};
  ${screen`md`({ borderRight: 'solid 0.5px var(--color-grey)' })};
`

export const ContentResult = styled.div`
  ${tw`grid content-start`};
`

export const ProductResults = styled.div`
  ${tw`grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8`}
`
