// import PropTypes from 'prop-types'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { isBlank, isPresent } from 'utils'

import collectionProductToProductCardSmallProps from 'converters/collectionProductToProductCardSmallProps'

import { globalSearchQueryAtom } from 'state/atoms'
import { topSearchResultsState } from 'state/selectors'

import Link from 'components/Link'
import ProductCardSmall from 'components/ProductCardSmall'
import Text from 'components/Text'

import { ContentResult, ContentResults, ProductResults, Root } from './SearchResults.style'

const SearchResults = () => {
  const globalSearchQuery = useRecoilValue(globalSearchQueryAtom)

  const searchResultsLoadable = useRecoilValueLoadable(topSearchResultsState)
  const { products, artisans, collections, articles } =
    searchResultsLoadable.state === 'hasValue' ? searchResultsLoadable.contents : {}

  if (isBlank(globalSearchQuery)) return null

  return (
    <>
      <Root>
        <ContentResults>
          <ContentResult>
            <Text weight="bold">Collections</Text>
            {isPresent(collections) ? (
              collections.map(({ handle, title }) => (
                <div key={handle}>
                  <Link text={title} href={`/collections/${handle}`} animated />
                </div>
              ))
            ) : (
              <Text style="italic">No results</Text>
            )}
          </ContentResult>
          <ContentResult>
            <Text weight="bold">Artisans</Text>
            {isPresent(artisans) ? (
              artisans.map(({ slug, name }) => (
                <div key={slug}>
                  <Link text={name} href={`/artisans/${slug}`} animated />
                </div>
              ))
            ) : (
              <Text style="italic">No results</Text>
            )}
          </ContentResult>
          <ContentResult>
            <Text weight="bold">Journal</Text>
            {isPresent(articles) ? (
              articles.map(({ slug, title }) => (
                <div key={slug}>
                  <Link text={title} href={`/journal/${slug}`} animated />
                </div>
              ))
            ) : (
              <Text style="italic">No results</Text>
            )}
          </ContentResult>
        </ContentResults>
        <ProductResults>
          {products?.map(collectionProductToProductCardSmallProps)?.map((product, index) => (
            <ProductCardSmall key={index} {...product} fractionalPrices={false} />
          ))}
        </ProductResults>
      </Root>
    </>
  )
}

SearchResults.propTypes = {}

SearchResults.defaultProps = {}

export default SearchResults
