import styled from 'styled-components'
import tw from 'twin.macro'

export const AltImage = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full z-10 opacity-0`};
  transition: opacity 0.2s;
`

export const Root = styled.div`
  ${tw`grid grid-cols-2 items-center cursor-pointer`};

  grid-template-columns: minmax(0, 140px) minmax(0, 1fr);

  &:hover ${AltImage} {
    ${tw`opacity-100`};
  }
`

export const Title = styled.div`
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; */

  /* We want the ellipsis to be in the correct font */
  /* font-family: var(--font-md);
  font-weight: 400;
  font-style: normal; */
`

export const ImageWrapper = styled.div`
  ${tw`relative`};
`

export const ImageContainer = styled.div`
  ${tw`block`};
`

export const PrimaryImage = styled.div`
  ${tw`block w-full`};
`

export const Main = styled.div`
  ${tw`pl-4`};
`

export const Prices = styled.div`
  ${tw`flex flex-wrap`};
`

export const CompareAtPrice = styled.div`
  ${tw`mr-2`};
  color: var(--color-gold);
`
