import { rem } from 'polished'
import styled from 'styled-components'
import tw, { screen } from 'twin.macro'

const rootXlNav = {
  minHeight: rem(68),
}

export const Root = styled.div`
  /* &:hover ${Dropdown} {
    ${({ $showHoverIndicator }) => $showHoverIndicator && tw`opacity-100 pointer-events-auto`};
  } */

  &:hover ${Inactive}::before {
    ${({ $showHoverIndicator }) => $showHoverIndicator && tw`transform transition-transform scale-x-100 opacity-100`};
  }

  &:hover ${Active}::after {
    ${({ $showHoverIndicator }) => $showHoverIndicator && tw`border-b-transparent`};
  }
`

export const Content = styled.a`
  ${tw`relative inline-flex justify-center items-center px-3 xlnav:px-4 bg-white whitespace-nowrap`}
  min-height: ${rem(45)};
  font-size: ${rem(13)};
  color: var(--color-gold-black);

  ${screen`xlnav`(rootXlNav)}
`

export const Inactive = styled(Content)`
  &::before {
    ${tw`absolute z-10 bottom-0 w-12 left-1/2 transform scale-x-0 -translate-x-1/2 opacity-0 transition transition-transform`};
    content: '';
    height: ${rem(3)};
    background-color: var(--color-gold-lightest);
  }
`

export const Active = styled(Inactive)`
  &::after {
    content: '';
    ${tw`absolute box-border top-0 bottom-0 w-2 border-solid border-0 border-t-8 border-b-8 left-1/2 -ml-1 select-none`};
    border-color: var(--color-gold-lightest);
  }
`

export const DropdownWrapper = styled.div`
  ${tw`hidden nav:block pointer-events-none pb-16 px-8 absolute z-50 opacity-0`}

  left: 50%;
  width: 100%;
  max-width: var(--screen-max);
  margin: 0 auto 0;
  transform: translateX(-50%);

  overflow: hidden;

  ${({ $isOpen }) => $isOpen && tw`pointer-events-auto opacity-100`};
`

export const Dropdown = styled.div`
  ${tw`opacity-0 pointer-events-none p-8`}

  box-shadow: inset 0 0.5px 0 0 var(--color-grey), 0 6px 5px rgb(0 0 0 / 10%);
  background-color: var(--color-bg);
  transform: translateY(-2rem);
  border: solid 0.5px var(--color-grey);
  border-top: 0;

  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  ${({ $isOpen }) => $isOpen && tw`opacity-100 pointer-events-auto translate-y-0`};
  ${({ $isOpen }) => $isOpen && { transform: 'translateY(0)' }};
`
