import { rem } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

const numberToPercentage = (number = 0, max = 60) => (number / max) * 100
const percentageToDegree = (percentage = 0) => (percentage * 360) / 100

const hourDeg = ({ hour }) => percentageToDegree(numberToPercentage(hour, 12))
const minuteDeg = ({ minute }) => percentageToDegree(numberToPercentage(minute, 60))
const secondDeg = ({ second }) => percentageToDegree(numberToPercentage(second, 60))

const hourHandDeg = ({ mark }) => 30 * mark

const hourMarkStyle = ({ mark }) =>
  mark === 12 && {
    width: '3px',
    backgroundColor: 'transparent',
    borderLeft: '1px solid var(--color-clock-grey)',
    borderRight: '1px solid var(--color-clock-grey)',
    marginLeft: '-1.5px',
  }

export const Root = styled.div`
  position: relative;
  display: block;
  width: ${rem(68)};
  height: ${rem(68)};

  &:before {
    content: '';
    ${tw`absolute rounded-full`};
    top: 50%;
    left: 50%;
    width: ${rem(6)};
    height: ${rem(6)};
    background-color: var(--color-clock-grey);
    transform: translate(-50%, -50%);
  }
`

export const RotateAnimation = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full`};
  animation: rotate ${({ $duration }) => $duration}s linear infinite;
  will-change: transform;
`

const Hand = styled.div`
  position: absolute;
  z-index: 1;
  top: 0%;
  left: 50%;
  display: block;
  width: 1px;
  height: 50%;
  margin-left: -0.5px;
  transform-origin: bottom center;
`

export const HourHand = styled(Hand)`
  svg {
    ${tw`absolute bottom-0 left-0`};
    margin-left: ${rem(-8)};
  }

  transform: rotateZ(${hourDeg}deg);
`

export const MinuteHand = styled(Hand)`
  svg {
    ${tw`absolute bottom-0 left-0`};
    margin-left: ${rem(-11)};
  }

  transform: rotateZ(${minuteDeg}deg);
`

export const SecondHand = styled(Hand)`
  background-color: var(--color-clock-grey);
  top: ${rem(7)};
  height: ${rem(27)};
  transform: rotateZ(${secondDeg}deg);
`

export const HourMark = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  display: block;
  width: 1px;
  margin-left: -0.5px;
  height: 50%;
  transform-origin: bottom;
  user-select: none;
  transform: rotatez(${hourHandDeg}deg);

  &::before {
    content: '';
    ${tw`absolute top-0 left-0 w-full`}
    height: ${rem(5)};
    background-color: var(--color-clock-grey);
    ${hourMarkStyle};
  }
`
