import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-x-8`}
  grid-template-columns: 1fr 3fr;
`

export const Products = styled.div`
  ${tw`grid grid-cols-2 gap-x-8`}
`

export const Main = styled.div`
  ${tw`pr-8`};
  border-right: solid 1px var(--color-grey);
`
