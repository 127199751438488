import PropTypes from 'prop-types'

import Link from 'components/Link'
import ProductCardMedium from 'components/ProductCardMedium'
import Spacer from 'components/Spacer'

import { Categories, CategoriesColumns, Category, Products, Root } from './ClothingNavigation.style'

const ClothingNavigation = ({ categories, products }) => {
  return (
    <Root>
      <Categories>
        <Link text="All Clothing" href="/collections/clothing" animated weight="semibold" />
        <Spacer y={1} />
        <CategoriesColumns>
          {categories?.map(({ title, handle }, index) => (
            <Category key={index}>
              <Link text={title} href={`/collections/${handle}`} animated />
            </Category>
          ))}
        </CategoriesColumns>
      </Categories>
      <Products>
        {products?.map((product, index) => (
          <ProductCardMedium key={index} {...product} baseUrl="/collections/clothing/" />
        ))}
      </Products>
    </Root>
  )
}

ClothingNavigation.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      handle: PropTypes.string,
    })
  ),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      handle: PropTypes.string,
      price: PropTypes.number,
      compareAtPrice: PropTypes.number,
      imageUrl: PropTypes.string,
      artisan: PropTypes.string,
    })
  ),
}

ClothingNavigation.defaultProps = {}

export default ClothingNavigation
