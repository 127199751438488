import * as Sentry from '@sentry/nextjs'
import {
  CartCreateMutation,
  CartLinesAddMutation,
  CartLinesRemoveMutation,
  CartLinesUpdateMutation,
  CartQuery,
} from 'gql/cart'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useMutation, useQuery } from 'urql'

import { isBlank, isPresent } from 'utils'

import { cartIdAtom } from 'state/atoms'

const useCart = () => {
  const [cartId, setCartId] = useRecoilState(cartIdAtom)

  const [cart, setCart] = useState({})
  const [count, setCount] = useState(0)
  const [lines, setLines] = useState([])

  // Create cart
  const [_cartCreateResult, cartCreate] = useMutation(CartCreateMutation)

  // Fetch cart
  const [result] = useQuery({
    query: CartQuery,
    variables: { id: cartId },
    pause: isBlank(cartId),
  })

  useEffect(() => {
    try {
      if (result.fetching || !result.data) return
      if (!result.data.cart) {
        createCart()
        return undefined
      }

      updateCart(result.data.cart)
    } catch (e) {
      console.error(e)
      Sentry.setContext('error', result.error)
      Sentry.captureException(e)
    }
  }, [result, createCart])

  const createCart = useCallback(async () => {
    const response = await cartCreate()
    const { data, error } = response
    try {
      const newCartId = data.cartCreate?.cart?.id
      setCartId(newCartId)
    } catch (e) {
      console.error(e)
      Sentry.setContext('error', error)
      Sentry.captureException(e)
    }
  }, [cartCreate, setCartId])

  // Initialize cart
  const initCart = useCallback(async () => {
    if (isPresent(cartId)) return

    createCart()
  }, [createCart, cartId])

  useEffect(() => {
    initCart()
  }, [initCart])

  // Add lines
  const [_cartLinesAddResult, cartLinesAdd] = useMutation(CartLinesAddMutation)
  const linesAdd = async (lines) => {
    const response = await cartLinesAdd({ cartId: cartId, lines })
    updateCart(response.data.cartLinesAdd.cart)
  }

  // Remove lines
  const [_cartLinesRemoveResult, cartLinesRemove] = useMutation(CartLinesRemoveMutation)
  const linesRemove = async (lineIds) => {
    const response = await cartLinesRemove({ cartId: cartId, lineIds })
    updateCart(response.data.cartLinesRemove.cart)
  }

  // Update lines
  const [_cartLinesUpdateResult, cartLinesUpdate] = useMutation(CartLinesUpdateMutation)
  const linesUpdate = async (lines) => {
    const response = await cartLinesUpdate({ cartId: cartId, lines })
    updateCart(response.data.cartLinesUpdate.cart)
  }

  const updateCart = (cart) => {
    if (!cart) return

    const newCart = { ...cart }

    setCart(newCart)
    setCount(newCart?.lines?.edges?.reduce((acc, { node }) => acc + node.quantity, 0) || 0)
    setLines(newCart?.lines?.edges?.map((edge) => edge.node) || [])
  }

  return {
    cart,
    lines,
    // prevCount,
    count,
    linesAdd,
    linesRemove,
    linesUpdate,
  }
}

export default useCart
