import PropTypes from 'prop-types'

import { isBlank } from 'utils'

import Text from 'components/Text'

import { Root } from './Header.style'

const Header = ({ size, children, ...props }) => {
  if (isBlank(children) && isBlank(props.html)) return null

  return (
    <Root $size={size}>
      <Text font="freight" {...props}>
        {children}
      </Text>
    </Root>
  )
}

Header.propTypes = {
  /**
   * Font size
   * */
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl']),
  /**
   * Text-like content
   * */
  children: PropTypes.node,
}

Header.defaultProps = {
  size: 'base',
}

export default Header
