import PropTypes from 'prop-types'

import Link from 'components/Link'
import Spacer from 'components/Spacer'

import CollectionCard from './CollectionCard'
import { Collections, LinkWrapper, Main, Root } from './CollectionsNavigation.style'

const CollectionsNavigation = ({ collections }) => {
  return (
    <Root>
      <Collections>
        {collections?.slice(0, 4)?.map((collection) => (
          <CollectionCard key={collection.handle} {...collection} />
        ))}
      </Collections>
      <Main>
        <Link text="All Collections" href="/collections" animated weight="semibold" />
        <Spacer y={1} />
        {collections?.slice(4)?.map(({ handle, title }) => (
          <LinkWrapper key={handle}>
            <Link href={`/collections/${handle}`} text={title} animated />
          </LinkWrapper>
        ))}
      </Main>
    </Root>
  )
}

CollectionsNavigation.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      handle: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

CollectionsNavigation.defaultProps = {}

export default CollectionsNavigation
