import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import { hourMarks } from './ClockFace.config'
import { HourHand, HourMark, MinuteHand, Root, RotateAnimation, SecondHand } from './ClockFace.style'
import HourHandSvg from './svg/hour-hand.svg'
import MinuteHandSvg from './svg/minute-hand.svg'

const ClockFace = ({ tz }) => {
  const time = useRef(DateTime.now().setZone(tz))

  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)
  const [second, setSecond] = useState(0)

  useEffect(() => {
    setHour(time.current.hour)
    setMinute(time.current.minute)
    setSecond(time.current.second + time.current.millisecond / 1000)
  }, [time])

  return (
    <Root>
      <RotateAnimation $duration={43200}>
        <HourHand hour={hour}>
          <HourHandSvg width={16} height={16} preserveAspectRatio="xMidYMin" />
        </HourHand>
      </RotateAnimation>
      <RotateAnimation $duration={3600}>
        <MinuteHand minute={minute}>
          <MinuteHandSvg width={22} height={22} preserveAspectRatio="xMidYMin" />
        </MinuteHand>
      </RotateAnimation>
      <RotateAnimation $duration={60}>
        <SecondHand second={second} />
      </RotateAnimation>
      {hourMarks.map((hourMark) => (
        <HourMark key={hourMark} mark={hourMark} />
      ))}
    </Root>
  )
}

ClockFace.propTypes = {
  tz: PropTypes.string.isRequired,
}

ClockFace.defaultProps = {
  tz: 'Europe/London',
}

export default ClockFace
