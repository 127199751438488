import PropTypes from 'prop-types'

import Text from 'components/Text'

import { Address, EmailAndTelephoneNumber, Name, NameAndAddress, Root } from './LocationDetails.style'

const LocationDetails = ({ name, address, emailAddress, telephoneNumber, openingHours }) => {
  return (
    <Root>
      <NameAndAddress>
        <Name>
          <Text font="proxima" size="xs" transform="uppercase" tracking="wide">
            {name}
          </Text>
        </Name>
        {address && (
          <Address>
            <Text html={address} />
          </Address>
        )}
      </NameAndAddress>
      <EmailAndTelephoneNumber>
        {emailAddress && (
          <a href={`mailto:${emailAddress}`}>
            <Text>{emailAddress}</Text>
          </a>
        )}
        {telephoneNumber && (
          <a href={`mailto:${telephoneNumber}`}>
            <Text>{telephoneNumber}</Text>
          </a>
        )}
      </EmailAndTelephoneNumber>
      {openingHours && <Text html={openingHours} />}
    </Root>
  )
}

LocationDetails.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  emailAddress: PropTypes.string,
  telephoneNumber: PropTypes.string,
  openingHours: PropTypes.string,
}

LocationDetails.defaultProps = {}

export default LocationDetails
