const plugin = require('tailwindcss/plugin')

const taMaxWidthPlugin = plugin(({ addUtilities }) => {
  const newUtilities = {
    '.ta-max-width': {
      'max-width': 'var(--screen-max-inner)',
      'margin-left': 'auto',
      'margin-right': 'auto',
    },

    '.ta-max-width-parent': {
      '& > *': {
        'max-width': 'var(--screen-max)',
        'margin-left': 'auto',
        'margin-right': 'auto',
      },
    },
  }

  addUtilities(newUtilities)
})

module.exports = {
  mode: 'jit',
  purge: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    // NOTE these need to be kept in sync with the CSS variables
    screens: {
      xsm: '23.125rem',
      sm: '36rem',
      md: '48rem',
      nav: '54.375rem',
      xlnav: '69.38rem',
      lg: '62rem',
      xl: '78rem',
      max: '100rem',
      lgmax: {
        max: '62rem',
      },
      mdmax: {
        max: '48rem',
      },
    },
    extend: {
      scale: {
        62: '62%',
        38: '38%',
      },
      spacing: {
        '62pc': '62%',
        '38pc': '38%',
        insetX: 'var(--spacing-inset-x)',
        insetY: 'var(--spacing-inset-y)',
      },
      maxWidth: {
        taprose: '48.625rem',
        tamax: '100rem',
        '1/2': '50%',
        watchesMax: '73.75rem',
        watchesMaxFlush: '90rem',
      },
      animation: {
        'spin-slow': 'spin 10s linear infinite',
      },
      // NOTE these need to be kept in sync with the CSS variables
      colors: {
        bg: 'var(--color-bg)',
        white: 'var(--color-white)',
        black: 'var(--color-black)',
        'overlay-bg': 'var(--color-overlay-bg)',
        text: 'var(--color-text)',
        gold: 'var(--color-gold)',
        'gold-on-gold-light-wcag': 'var(--color-gold-on-gold-light-wcag)',
        'gold-light': 'var(--color-gold-light)',
        'gold-lightest': 'var(--color-gold-lightest)',
        'blue-grey': 'var(--color-blue-grey)',
        'gold-black': 'var(--color-gold-black)',
        'gold-white': 'var(--color-gold-white)',
        'gold-white-line': 'var(--color-gold-white-line)',
        'gold-white-dark': 'var(--color-gold-white-dark)',
        'input-bg': 'var(--color-input-bg)',
        'input-text': 'var(--color-input-text)',
        'input-placeholder': 'var(--color-input-placeholder)',
        grey: 'var(--color-grey)',
        'grey-wcag': 'var(--color-grey-wcag)',
        'grey-gold': 'var(--color-grey-gold)',
        'dark-grey': 'var(--color-dark-grey)',
        'darker-grey': 'var(--color-darker-grey)',
        'clock-grey': 'var(--color-clock-grey)',
        danger: 'var(--color-danger)',
        'black-bean': 'var(--color-black-bean)',
        'seal-brown': 'var(--color-seal-brown)',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [taMaxWidthPlugin],
}
