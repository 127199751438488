import PropTypes from 'prop-types'

import { Root } from './Spacer.style'

const Spacer = ({ x, y }) => {
  return <Root $x={x} $y={y} />
}

Spacer.propTypes = {
  x: PropTypes.any,
  y: PropTypes.any,
}

Spacer.defaultProps = {}

export default Spacer
