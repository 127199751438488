// import { Suspense } from 'react'
import { PrismicPreview } from '@prismicio/next'
import { PrismicProvider } from '@prismicio/react'
import * as Sentry from '@sentry/nextjs'
import { repoName } from 'config/prismic'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { useEffect } from 'react'
import { RecoilRoot, useRecoilState } from 'recoil'
import { SWRConfig } from 'swr'
import { Provider as UrqlProvider, createClient } from 'urql'

import { globalSearchOpenAtom, globalSearchQueryAtom, smallScreenNavigationIsOpenAtom } from 'state/atoms'

import Announcements from 'components/Announcements'
import SearchModal from 'components/SearchModal'
import SiteFooter from 'components/SiteFooter'
import SiteHeader from 'components/SiteHeader'

import 'styles/globals.css'
import 'styles/rc-slider.css'

const urqlClient = createClient({
  url: 'https://thearmourygroup.myshopify.com/api/2022-01/graphql.json',
  fetchOptions: () => {
    return {
      headers: { 'X-Shopify-Storefront-Access-Token': 'b1a0f813f63e42f9685cf6fdae6a3893' },
    }
  },
})

const swrConfig = {
  fetcher: (resource, init) => fetch(`${process.env.NEXT_PUBLIC_API_URI}${resource}`, init).then((res) => res.json()),
}

const Armoury = ({ children }) => {
  const [_globalSearchQuery, setGlobalSearchQuery] = useRecoilState(globalSearchQueryAtom)
  const [_globalSearchOpen, setGlobalSearchOpen] = useRecoilState(globalSearchOpenAtom)
  const [_smallScreenNavigationIsOpen, setSmallScreenNavigationIsOpen] = useRecoilState(smallScreenNavigationIsOpenAtom)

  const router = useRouter()

  useEffect(() => {
    const handleRouteChangeStart = () => {
      try {
        setGlobalSearchQuery('')
        setSmallScreenNavigationIsOpen(false)
        setGlobalSearchOpen(false)
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
      }
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [setGlobalSearchQuery, setSmallScreenNavigationIsOpen, setGlobalSearchOpen, router.events])

  return <>{children}</>
}

function MyApp({ Component, pageProps }) {
  // useEffect(() => {
  //   window.onbeforeunload = () => {
  //     const sessionProductHistory = sessionStorage.getItem('productHistory') || '[]'
  //     localStorage.setItem('productHistory', sessionProductHistory)
  //   }
  // }, [])

  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />)
  }

  return (
    <PrismicProvider
      internalLinkComponent={({ href, children, ...props }) => (
        <Link href={href} {...props}>
          {children}
        </Link>
      )}
    >
      <PrismicPreview repositoryName={repoName}>
        <GoogleAnalytics trackPageViews />
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <RecoilRoot>
          <UrqlProvider value={urqlClient}>
            <SWRConfig value={swrConfig}>
              <Armoury>
                <div>
                  <Announcements />
                </div>
                <SiteHeader />
                {/* <div>
            <ProductHistory />
          </div> */}
                <main>
                  <Component {...pageProps} />
                </main>
                <SiteFooter />
                <SearchModal />
              </Armoury>
            </SWRConfig>
          </UrqlProvider>
        </RecoilRoot>
      </PrismicPreview>
    </PrismicProvider>
  )
}

export default MyApp
