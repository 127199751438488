import Link from 'next/link'
import PropTypes from 'prop-types'

import ClockFace from 'components/ClockFace'
import Divider from 'components/Divider'
import LocationDetails from 'components/LocationDetails'
import SocialLinks from 'components/SocialLinks'

import {
  Clock,
  DividerWrapper,
  LargeScreenTitle,
  Root,
  SmallScreenTitle,
  SocialMedia,
  Stores,
  Title,
} from './LocationsGroup.style'

const titleToTz = {
  'New York': 'America/New_York',
  'Hong Kong': 'Asia/Hong_Kong',
}

const LocationsGroup = ({ title, socialLinks, stores }) => {
  const htmlTitle = title?.replace(' ', '<br />')

  return (
    <Root>
      <SmallScreenTitle>
        <Link href="/contact" passHref>
          <Title font="ernestine" transform="uppercase">
            <span dangerouslySetInnerHTML={{ __html: htmlTitle }} />
          </Title>
        </Link>
      </SmallScreenTitle>
      <LargeScreenTitle>
        <Title font="ernestine" transform="uppercase">
          <span dangerouslySetInnerHTML={{ __html: htmlTitle }} />
        </Title>
      </LargeScreenTitle>
      <Clock>
        <ClockFace tz={titleToTz[title]} />
      </Clock>
      <SocialMedia>
        <SocialLinks {...socialLinks} />
      </SocialMedia>
      <DividerWrapper>
        <Divider />
      </DividerWrapper>
      <Stores>{stores && stores.map((store) => <LocationDetails key={store.name} {...store} />)}</Stores>
    </Root>
  )
}

LocationsGroup.propTypes = {
  title: PropTypes.string.isRequired,
  socialLinks: PropTypes.object,
  stores: PropTypes.arrayOf(PropTypes.object),
}

LocationsGroup.defaultProps = {}

export default LocationsGroup
