import { rem } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

export const Content = styled.div`
  /* ${tw`relative inline-flex justify-center items-center px-3 xlnav:px-4 bg-white whitespace-nowrap`} */
  ${tw`relative flex items-center px-3 xlnav:px-4 whitespace-nowrap`}
  min-height: ${rem(45)};
  font-size: ${rem(13)};
  background-color: var(--color-bg);
  color: var(--color-gold-black);
`

export const Inactive = styled(Content)`
  &::before {
    ${tw`absolute z-10 bottom-0 w-12 left-1/2 transform scale-x-0 -translate-x-1/2 opacity-0 transition transition-transform	`};
    content: '';
    height: ${rem(3)};
    background-color: var(--color-gold-lightest);
  }
`

export const Active = styled(Inactive)`
  &::after {
    content: '';
    ${tw`absolute box-border top-0 bottom-0 w-2 border-solid border-0 border-t-8 border-b-8 left-1/2 -ml-1 select-none`};
    border-color: var(--color-gold-lightest);
  }
`

export const Root = styled.a`
  display: block;
`
