import { atom } from 'recoil'

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    if (typeof localStorage === 'undefined') return

    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue, _, isReset) => {
      if (typeof localStorage === 'undefined') return

      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

const sessionStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    if (typeof sessionStorage === 'undefined') return

    const savedValue = sessionStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue, _, isReset) => {
      if (typeof sessionStorage === 'undefined') return

      isReset ? sessionStorage.removeItem(key) : sessionStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const smallScreenNavigationIsOpenAtom = atom({
  key: 'smallScreenNavigationIsOpen',
  default: false,
})

export const globalSearchOpenAtom = atom({
  key: 'globalSearchOpen',
  default: false,
})

export const globalSearchQueryAtom = atom({
  key: 'globalSearchQuery',
  default: '',
})

export const customerAccessTokenAtom = atom({
  key: 'customerAccessToken',
  default: null,
  effects: [localStorageEffect('customerAccessToken')],
})

export const productHistorySessionAtom = atom({
  key: 'productHistorySession',
  default: [],
  effects: [sessionStorageEffect('productHistory')],
})

export const productHistoryStorageAtom = atom({
  key: 'productHistoryStorage',
  default: [],
  effects: [localStorageEffect('productHistory')],
})

export const cartIdAtom = atom({
  key: 'cartId',
  default: [],
  effects: [localStorageEffect('checkoutId')],
})

// export const previousProductHistoryAtom = atom({
//   key: 'previousProductHistory',
//   default: [],
//   effects: [sessionStorageEffect('previousProductHistory')],
// })
