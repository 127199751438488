import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../tailwind.config.js'

const tw = resolveConfig(tailwindConfig)

export const Root = styled.div`
  ${({ $x }) => $x && { width: tw.theme.spacing[$x] }}
  ${({ $y }) => $y && { height: tw.theme.spacing[$y] }}
  visibility: hidden;
  user-select: none;
`
