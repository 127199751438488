import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.span`
  ${tw`inline-block`};
  color: var(--color-gold);
  text-decoration: underline;
  cursor: pointer;
`

export const AnimatedRoot = styled.span`
  /* ${tw`relative inline`}; */
  color: var(--color-gold);
  cursor: pointer;

  background-image: linear-gradient(transparent calc(100% - 1px), var(--color-gold) 1px);
  background-repeat: repeat-y;
  background-size: 0% 100%;
  transition: background-size 0.2s;
  padding-bottom: 2px;

  &:hover {
    background-size: 100% 100%;
  }

  /* &::before {
    content: '';
    ${tw`absolute z-10 w-full h-px left-0 bottom-px opacity-0`};
    background-color: currentColor;
    transition: opacity 0.2s, transform 0.2s;
    transform: scale(0, 0.5);
    transform-origin: center left;
    backface-visibility: hidden;
    transform: scaleX(0) scaleY(0.5);
  }

  &:hover::before {
    ${tw`opacity-100`};
    transform: scaleX(1) scaleY(0.5);
  } */
`
