import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Header from 'components/Header'
import Link from 'components/Link'
import ProductCardMedium from 'components/ProductCardMedium'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Main, Products, Root } from './NewArrivalsNavigation.style'

const NewArrivalsNavigation = ({ header, body, cta, ctaLink, products }) => {
  return (
    <Root>
      <Main>
        <Header size="xs">{header}</Header>
        <Spacer y={2} />
        <Text html={body} />
        <Spacer y={2} />
        {isPresent(cta) && isPresent(ctaLink) && <Link text={cta} href={ctaLink} animated />}
      </Main>
      <Products>
        {products?.map((product, index) => (
          <ProductCardMedium key={index} {...product} baseUrl="/collections/new-arrivals/" />
        ))}
      </Products>
    </Root>
  )
}

NewArrivalsNavigation.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  cta: PropTypes.string,
  ctaLink: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      handle: PropTypes.string,
      price: PropTypes.number,
      compareAtPrice: PropTypes.number,
      imageUrl: PropTypes.string,
      artisan: PropTypes.string,
    })
  ),
}

NewArrivalsNavigation.defaultProps = {}

export default NewArrivalsNavigation
