import * as prismicH from '@prismicio/helpers'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { isBlank, isPresent } from 'utils'
import prismicAsLink from 'utils/prismicAsLink'

import siteSubNavigation from 'data/siteSubNavigation.json'

import AccessoriesNavigation from 'components/AccessoriesNavigation'
import ArtisansNavigation from 'components/ArtisansNavigation'
import ClothingNavigation from 'components/ClothingNavigation'
import CollectionsNavigation from 'components/CollectionsNavigation'
import ContactNavigation from 'components/ContactNavigation'
import CustomAndBespokeNavigation from 'components/CustomAndBespokeNavigation'
import FootwearNavigation from 'components/FootwearNavigation'
import JournalNavigation from 'components/JournalNavigation'
import NewArrivalsNavigation from 'components/NewArrivalsNavigation'
import Text from 'components/Text'

import { Active, Dropdown, DropdownWrapper, Inactive, Root } from './SiteNavigationItem.style'

const navigations = {}

navigations['Artisans'] = {
  component: ArtisansNavigation,
  props: (siteNavigation) => {
    let artisans = siteNavigation.artisans || []

    const artisansNavigation = siteNavigation.artisansNavigation || {}

    return {
      artisans,
      ...artisansNavigation,
    }
  },
}

navigations['Custom & Bespoke'] = {
  component: CustomAndBespokeNavigation,
  props: (siteNavigation) => {
    const columns =
      siteNavigation.customAndBespokeNavigation?.content?.map((column) => ({
        imageUrl: column.image?.url,
        header: column.content_header,
        body: prismicH.asHTML(column.content_body),
        cta: column.content_cta,
        ctaLink: prismicAsLink(column.content_cta_link),
      })) || []

    return { columns }
  },
}

navigations['Journal'] = {
  component: JournalNavigation,
  props: (siteNavigation) => {
    const articles = siteNavigation?.articles

    const articleCategories = siteNavigation?.articleCategories?.map((articleCategory) => ({
      handle: articleCategory.uid,
      title: articleCategory.data.title,
    }))

    return { articles, articleCategories }
  },
}

navigations['Contact'] = {
  component: ContactNavigation,
  props: (siteNavigation) => {
    return {
      header: siteNavigation.contactNavigation?.header,
      body: prismicH.asHTML(siteNavigation.contactNavigation?.body),
      cta: siteNavigation.contactNavigation?.cta,
      ctaLink: prismicAsLink(siteNavigation.contactNavigation?.cta_link),
      locations: siteNavigation.locations,
    }
  },
}

navigations['New Arrivals'] = {
  component: NewArrivalsNavigation,
  props: (siteNavigation) => {
    return {
      header: siteNavigation.newArrivalsNavigation?.header,
      body: prismicH.asHTML(siteNavigation.newArrivalsNavigation?.body),
      cta: siteNavigation.newArrivalsNavigation?.cta,
      ctaLink: prismicAsLink(siteNavigation.newArrivalsNavigation?.cta_link),
      products: siteNavigation.newArrivalsProducts,
    }
  },
}

navigations['Clothing'] = {
  component: ClothingNavigation,
  props: (siteNavigation) => {
    return {
      categories: siteNavigation.clothingCategories,
      products: siteNavigation.clothingProducts,
    }
  },
}

navigations['Accessories'] = {
  component: AccessoriesNavigation,
  props: (siteNavigation) => {
    return {
      categories: siteNavigation.accessoriesCategories,
      products: siteNavigation.accessoriesProducts,
    }
  },
}

navigations['Footwear'] = {
  component: FootwearNavigation,
  props: (siteNavigation) => {
    return {
      categories: siteNavigation.footwearCategories,
      products: siteNavigation.footwearProducts,
    }
  },
}

navigations['Collections'] = {
  component: CollectionsNavigation,
  props: ({ specialCollections }) => {
    return {
      collections: specialCollections?.map(({ collection }) => collection),
    }
  },
}

const SiteNavigationItem = ({ text, href, active, showHoverIndicator }) => {
  const { asPath, events } = useRouter()
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [openTimeout, setOpenTimeout] = useState(false)

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setDropdownIsOpen(false)
    }

    events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [events])

  if (isBlank(text)) return null

  const isActive = active || asPath == href
  const ActiveOrInactive = isActive ? Active : Inactive
  const navigation = navigations[text]
  const NavigationComponent = navigation?.component
  const navigationProps = navigation?.props(siteSubNavigation)

  const handleMouseEnter = () => {
    const timeout = window.setTimeout(() => setDropdownIsOpen(true), 300)
    setOpenTimeout(timeout)
  }

  const handleMouseLeave = () => {
    setDropdownIsOpen(false)
    window.clearTimeout(openTimeout)
  }

  return (
    <Root $showHoverIndicator={showHoverIndicator} onMouseLeave={handleMouseLeave}>
      <Link href={href} passHref legacyBehavior>
        <ActiveOrInactive onMouseEnter={handleMouseEnter}>
          <Text font="proxima" tracking="wide" transform="uppercase">
            {text}
          </Text>
        </ActiveOrInactive>
      </Link>
      {isPresent(NavigationComponent) && (
        <DropdownWrapper $isOpen={dropdownIsOpen}>
          <Dropdown $isOpen={dropdownIsOpen}>
            <NavigationComponent {...navigationProps} />
          </Dropdown>
        </DropdownWrapper>
      )}
    </Root>
  )
}

SiteNavigationItem.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
}

SiteNavigationItem.defaultProps = {
  active: false,
  showHoverIndicator: true,
}

export default SiteNavigationItem
