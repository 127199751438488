const isBase64Encoded = (string) => {
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/
  return base64Regex.test(string)
}

const decodeBase64 = (val) => {
  if (!val) return val

  if (!isBase64Encoded(val)) return val

  return Buffer.from(val, 'base64').toString('binary')
}

const shopifyGidToId = (gid) => {
  if (!gid) return gid

  const parts = decodeBase64(gid).split('/')
  return parts[parts.length - 1]
}

export default shopifyGidToId
