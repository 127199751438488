import { rem } from 'polished'
import styled from 'styled-components'
import tw, { screen } from 'twin.macro'

import UnstyledAccountIcon from './svg/account_icon.svg'
import UnstyledCartIcon from './svg/cart_icon.svg'
import UnstyledHamburger from './svg/hamburger.svg'
import UnstyledLogo from './svg/logo.svg'
import UnstyledLogoComplex from './svg/logo_complex.svg'
import UnstyledSearchIcon from './svg/search_icon.svg'

const gridTemplateAreas = {
  nav: {
    gridTemplateColumns: 'min-content min-content minmax(min-content, 100%) min-content',
    gridTemplateAreas: `
    'logo logo search cart'
    'hr-top hr-top hr-top hr-top'
    'primary primary primary primary'
    'hr-middle hr-middle hr-middle hr-middle'
    'secondary secondary secondary secondary'
    'hr-bottom hr-bottom hr-bottom hr-bottom'`,
  },
  xlnav: {
    gridTemplateColumns: 'min-content min-content minmax(min-content, 100%) min-content',
    gridTemplateAreas: `
    'logo primary primary primary'
    'hr-middle hr-middle hr-middle hr-middle'
    'secondary secondary search cart'
    'hr-bottom hr-bottom hr-bottom hr-bottom'`,
  },
}

export const Root = styled.div`
  position: sticky;
  top: 0;
  ${screen`nav`({ top: -70 })};
  z-index: 30;
  background-color: var(--color-bg);
`

export const Main = styled.div`
  position: relative;
  background-color: var(--color-bg);
  /* background-color: yellow; */
  display: grid;
  grid-template-columns: min-content minmax(min-content, 100%) min-content min-content;
  align-items: center;
  grid-template-areas:
    'hamburger logo . account cart'
    'hr-top hr-top hr-top hr-top hr-top'
    'essential essential essential essential search'
    'hr-bottom hr-bottom hr-bottom hr-bottom hr-bottom';
  ${screen`nav`(gridTemplateAreas['nav'])};
  ${screen`xlnav`(gridTemplateAreas['xlnav'])};

  width: 100%;
  max-width: var(--screen-max);
  margin: 0 auto;
`

export const Hamburger = styled(UnstyledHamburger)`
  ${screen`nav`(tw`hidden`)};
`

export const Logo = styled(UnstyledLogo)`
  ${tw`nav:hidden scale-62`};
  margin-left: -50px;
  ${screen`xsm`(tw`scale-100`)};
  ${screen`xsm`({ marginLeft: -22 })};
  ${screen`sm`({ marginLeft: 0 })};
`

export const LogoComplex = styled(UnstyledLogoComplex)`
  ${tw`hidden nav:block`};
`

export const AccountIcon = styled(UnstyledAccountIcon)``

export const CartIcon = styled(UnstyledCartIcon)``

export const Hr = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background-color: var(--color-grey);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 1px;
    width: 100vw;
    transform: translateX(-50vw);
    background-color: var(--color-grey);
  }

  @media (min-resolution: 2dppx) {
    transform: scaleY(0.5);
  }

  @media (min-resolution: 3dppx) {
    transform: scaleY(0.33);
  }

  @media (min-resolution: 4dppx) {
    transform: scaleY(0.25);
  }
`

export const HamburgerArea = styled.div`
  grid-area: hamburger;
  ${tw`cursor-pointer flex items-center justify-center z-10 select-none`}
  width: ${rem(45)};
  height: ${rem(45)};
  background-color: var(--color-bg);
  ${screen`nav`(tw`hidden`)};

  .mantine-Burger-burger,
  .mantine-Burger-burger::before,
  .mantine-Burger-burger::after {
    height: 1px;
  }
`

export const LogoArea = styled.div`
  grid-area: logo;
`

export const AccountArea = styled.div`
  grid-area: account;
  ${tw`cursor-pointer flex items-center justify-center`}
  width: ${rem(45)};
  height: ${rem(45)};
  ${screen`nav`(tw`hidden`)};
`

export const AccountItem = styled.a`
  ${tw`relative cursor-pointer flex items-center justify-center`}
  min-width: ${rem(45)};
  height: ${rem(45)};
  ${screen`nav`({ minWidth: rem(68), height: rem(68) })};

  &::before {
    ${tw`absolute z-10 bottom-0 w-12 left-1/2 transform scale-x-0 -translate-x-1/2 opacity-0 transition transition-transform	`};
    content: '';
    height: ${rem(3)};
    background-color: var(--color-gold-lightest);
  }

  &:hover::before {
    ${tw`transform transition-transform scale-x-100 opacity-100`};
  }
`

export const EssentialNavArea = styled.div`
  grid-area: essential;
  ${screen`nav`(tw`hidden`)};
`

export const PrimaryNavArea = styled.div`
  grid-area: primary;
  ${tw`hidden`};
  ${screen`nav`({ display: 'block', justifySelf: 'center' })}
  ${screen`xlnav`({ justifySelf: 'end' })}
`

export const SecondaryNavArea = styled.div`
  grid-area: secondary;
  ${tw`hidden`};
  ${screen`nav`({ display: 'block', justifySelf: 'center' })}
  ${screen`xlnav`({ justifySelf: 'start' })}
`

export const SearchArea = styled.div`
  ${tw`flex justify-end`};
  position: absolute;
  top: calc(var(--nav-height) / 2);
  right: 0;
  ${screen`nav`({
    position: 'static',
    bottom: 'auto',
    right: 'auto',
    gridArea: 'search',
    borderRight: '0.5px solid var(--color-grey)',
  })};
`

export const SearchItem = styled.button`
  ${tw`relative cursor-pointer flex items-center justify-center px-4`}
  min-width: ${rem(45)};
  height: ${rem(45)};
  ${screen`nav`({ minWidth: rem(68), height: rem(68) })};

  &::before {
    ${tw`absolute z-10 bottom-0 w-12 left-1/2 transform scale-x-0 -translate-x-1/2 opacity-0 transition transition-transform	`};
    content: '';
    height: ${rem(3)};
    background-color: var(--color-gold-lightest);
  }

  &:hover::before {
    ${tw`transform transition-transform scale-x-100 opacity-100`};
  }
`

export const SearchIcon = styled(UnstyledSearchIcon)`
  width: ${rem(18)};
  height: ${rem(17)};
`

export const CartArea = styled.div`
  grid-area: cart;
`
export const CartItem = styled.a`
  ${tw`relative cursor-pointer flex items-center justify-center px-4`}
  min-width: ${rem(45)};
  height: ${rem(45)};
  ${screen`nav`({ minWidth: rem(68), height: rem(68) })};

  &::before {
    ${tw`absolute z-10 bottom-0 w-12 left-1/2 transform scale-x-0 -translate-x-1/2 opacity-0 transition transition-transform	`};
    content: '';
    height: ${rem(3)};
    background-color: var(--color-gold-lightest);
  }

  &:hover::before {
    ${tw`transform transition-transform scale-x-100 opacity-100`};
  }
`

export const CartItemActive = styled(CartItem)`
  &::after {
    content: '';
    ${tw`absolute box-border top-0 bottom-0 w-2 border-solid border-0 border-t-8 border-b-8 left-1/2 -ml-1 select-none`};
    border-color: var(--color-gold-lightest);
  }

  &:hover::after {
    ${tw`border-b-transparent`};
  }
`

export const CartCount = styled.span`
  ${tw`inline align-middle mx-2 nav:ml-4 nav:mr-0 h-8 px-2`};
  border-style: double;
  border-width: 3px;
  border-color: var(--color-white);
  color: var(--color-white);
  background-color: var(--color-gold);

  padding-bottom: 3px;
`

export const HrTop = styled(Hr)`
  grid-area: hr-top;
  ${screen`xlnav`(tw`hidden`)};
`

export const HrMiddle = styled(Hr)`
  grid-area: hr-middle;
  ${tw`hidden`}
  ${screen`nav`(tw`block`)};
`

export const HrBottom = styled(Hr)`
  grid-area: hr-bottom;
`

export const SmallScreenNav = styled.div`
  ${tw`absolute top-0 left-0 w-full z-20 nav:hidden`};
  height: ${({ $isOpen }) => ($isOpen ? '100vh' : '0')};
  overflow: hidden;
  transition: height 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  pointer-events: none;
`

export const SmallScreenNavInner = styled.div`
  ${tw`absolute top-0 bottom-0 left-0 w-full overflow-auto overscroll-contain`};
  top: 46px;
  background-color: var(--color-bg);
  pointer-events: auto;
`
