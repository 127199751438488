import styled from 'styled-components'
import tailwindConfig from 'tailwind.config.js'
import resolveConfig from 'tailwindcss/resolveConfig'
import tw from 'twin.macro'

const { theme } = resolveConfig(tailwindConfig)

const fonts = {
  unica: {
    fontFamily: 'var(--font-nhu)',
    fontWeight: 300,
    letterSpacing: '0.028em',
  },
  freight: {
    fontFamily: 'var(--font-md)',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  proxima: {
    fontFamily: 'var(--font-gotham)',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  stint: {
    fontFamily: 'var(--font-stint)',
  },
  ernestine: {
    fontFamily: 'var(--font-archer)',
    fontWeight: '300',
    fontStyle: 'normal',
  },
}

const font = ({ $font }) => fonts[$font]

const size = ({ $size }) => {
  if (!$size || $size === 'inherit') return null
  // if ($size === 'inherit') return { fontSize: 'inherit' }

  const fontSize = theme.fontSize[$size]
  return {
    fontSize: fontSize[0],
    // Tailwind provides line heights too
    // ...fontSize[1],
  }
}

const trackings = {
  normal: {
    letterSpacing: 'normal',
  },
  wide: {
    letterSpacing: '0.15em',
  },
}

const tracking = ({ $tracking }) => trackings[$tracking]

const transforms = {
  none: {
    textTransform: 'none',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}

const transform = ({ $transform }) => transforms[$transform]

const weights = {
  normal: {
    fontWeight: 300,
  },
  semibold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
}

const weight = ({ $weight }) => weights[$weight]

const styles = {
  normal: {
    fontStyle: 'normal',
  },
  italic: {
    fontStyle: 'italic',
  },
}

const style = ({ $style }) => styles[$style]

const prose = ({ $prose }) => $prose && tw`max-w-taprose`

const color = ({ $color }) => {
  if ($color) return { color: $color }

  return null
}

const linkColor = ({ $color = 'var(--color-gold)' }) => {
  if ($color) return { color: $color }

  return null
}

export const Root = styled.span`
  ${tracking};
  ${font};
  ${size};
  ${transform};
  ${weight};
  ${style};
  ${prose};
  ${color};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-md);
    margin-bottom: 1em;

    strong,
    b {
      font-weight: normal;
    }
  }

  h1 {
    ${tw`text-5xl`};
  }

  h2 {
    ${tw`text-4xl`};
  }

  h3 {
    ${tw`text-2xl`};
  }

  h4 {
    ${tw`text-2xl`};
  }

  h5,
  h6 {
  }

  ul,
  ol {
    margin-bottom: 1em;
    padding-left: 0;

    li {
      list-style: none;
    }
  }

  strong,
  b {
    ${({ $font }) => $font === 'unica' && tw`font-semibold`};
  }

  em,
  i {
    ${({ $font }) => ($font === 'unica' ? tw`italic` : tw`not-italic`)};
  }

  a {
    ${tw`underline`};
    ${linkColor}
  }

  p + p {
    margin-top: 1em;
  }

  /* Any header not preceeded by another header */
  h1:not(h1 + h1),
  h2:not(h2 + h1),
  h3:not(h3 + h1),
  h4:not(h4 + h1),
  h5:not(h5 + h1),
  h6:not(h6 + h1) {
    margin-top: 1.75em;
  }

  *:last-child {
    margin-bottom: 0;
  }
`
