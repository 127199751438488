import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`flex items-center`};
`

export const Line = styled.div`
  ${tw`relative h-px flex-grow`};
  background-color: var(--color-grey);
  ${({ css }) => css}
`

export const TextWrapper = styled.div`
  ${tw`px-4`};
  text-align: center;
  max-width: 62%;
`
