import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import postJSON from 'utils/postJSON'

import { Button, Input, Root, Submitted, Submitting } from './NewsletterSubscribeForm.style'

const onSubmit = async (data) => await postJSON(`${process.env.NEXT_PUBLIC_API_URI}/subscribe`, data)

const defaultValues = {
  emailAddress: '',
}

const NewsletterSubscribeForm = ({ successMessage }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues,
  })

  if (isSubmitting) return <Submitting>Subscribing…</Submitting>

  if (isSubmitSuccessful) return <Submitted>{successMessage}</Submitted>

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Input
        placeholder="Your Email Address"
        {...register('emailAddress', { required: true, pattern: /.+@.+/ })}
        error={errors.emailAddress}
      />
      <Button type="submit">Subscribe</Button>
    </Root>
  )
}

NewsletterSubscribeForm.propTypes = {
  successMessage: PropTypes.string,
}

NewsletterSubscribeForm.defaultProps = {
  successMessage: 'Thank you for subscribing.',
}

export default NewsletterSubscribeForm
