import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import Text from 'components/Text'

import { ChevronIcon, GroupItems, GroupText, Root } from './SiteMenuItemGroup.style'
import Chevron from './svg/chevron.svg'

const SiteMenuItemGroup = ({ text, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [height, setHeight] = useState(0)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    isOpen ? setHeight('auto') : setHeight(0)
  }, [isOpen])

  return (
    <Root>
      <GroupText onClick={toggleOpen}>
        <Text font="proxima" tracking="wide" transform="uppercase">
          {text}
        </Text>
        <ChevronIcon $isOpen={isOpen}>
          <Chevron width={16} height={9} />
        </ChevronIcon>
      </GroupText>
      <AnimateHeight duration={300} height={height}>
        <GroupItems>{children}</GroupItems>
      </AnimateHeight>
    </Root>
  )
}

SiteMenuItemGroup.propTypes = {
  text: PropTypes.string.isRequired,
}

SiteMenuItemGroup.defaultProps = {}

export default SiteMenuItemGroup
