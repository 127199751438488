import Image from 'next/image'
import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Header from 'components/Header'
import Link from 'components/Link'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { ArtisanLink, Artisans, Main, Root } from './ArtisansNavigation.style'

const baseUrl = '/artisans/'

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}&ar=6:4&fit=crop`
}

const ArtisansNavigation = ({ artisans, header, body, cta, ctaLink, imageUrl }) => {
  return (
    <Root>
      <Artisans>
        {isPresent(artisans) &&
          artisans?.map(({ handle, name }) => (
            <ArtisanLink key={handle}>
              <Link text={name} href={baseUrl + handle} key={handle} animated />
            </ArtisanLink>
          ))}
      </Artisans>
      <Main>
        <Header size="xs">{header}</Header>
        <Spacer y={2} />
        <Text html={body} />
        <Spacer y={2} />
        {isPresent(cta) && isPresent(ctaLink) && <Link text={cta} href={ctaLink} animated />}
        <Spacer y={7} />
        {isPresent(imageUrl) && (
          <Image
            loader={loader}
            width="600"
            height="400"
            src={imageUrl}
            alt=""
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        )}
      </Main>
    </Root>
  )
}

ArtisansNavigation.propTypes = {
  artisans: PropTypes.arrayOf(
    PropTypes.shape({
      handle: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  header: PropTypes.string,
  body: PropTypes.string,
  cta: PropTypes.string,
  ctaLink: PropTypes.string,
  imageUrl: PropTypes.string,
}

ArtisansNavigation.defaultProps = {}

export default ArtisansNavigation
