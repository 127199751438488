import { rem } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  background: var(--color-bg);

  * {
    flex: none;
  }
`

export const SearchItem = styled.button`
  ${tw`block w-full px-4 text-left`};
  min-height: ${rem(45)};
  background-color: var(--color-input-bg);

  [class^='Text'] {
    color: var(--color-input-placeholder);
  }
`

export const Item = styled.div`
  border-bottom: solid 1px var(--color-grey);
`

export const Locations = styled.div`
  ${tw`relative grid grid-cols-2 py-6 mt-auto`}

  &:before {
    content: '';
    ${tw`absolute top-1/2 left-1/2 transform -translate-y-1/2`};
    height: 62%;
    margin-left: -1px;
    width: 1px;
    background-color: var(--color-grey);
  }
`

export const Location = styled.div`
  ${tw`flex flex-col justify-center items-center`}
  font-size: ${rem(21)};
`
export const LocationTitle = styled.div`
  ${tw`mb-2`}
`

export const SocialLinks = styled.div`
  ${tw`grid auto-cols-min grid-cols-4 place-items-center gap-x-5`}
`

export const SocialLink = styled.a``
