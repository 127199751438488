import { rem } from 'polished'
import styled from 'styled-components'
import tw, { screen } from 'twin.macro'

import SearchCloseIcon from './svg/search_close_icon.svg'
import SearchIcon from './svg/search_icon.svg'

export const Root = styled.div`
  ${tw`relative flex justify-end`}
  width: ${rem(45)};
  height: ${rem(45)};
  ${screen`nav`(tw`w-auto h-auto`)};

  ${({ $focussed }) => $focussed && tw`w-full`};
`

export const Placeholder = styled.div`
  ${tw`relative flex items-center justify-center px-6 xlnav:px-8 cursor-pointer select-none`};
  width: min-content;

  &::before {
    ${tw`absolute z-10 bottom-0 w-12 left-1/2 transform scale-x-0 -translate-x-1/2 opacity-0 transition transition-transform`};
    content: '';
    height: ${rem(3)};
    /* background-color: var(--color-gold-lightest); */
    background-color: var(--color-gold-lightest);

    ${({ $focussed }) => $focussed && tw`transform transition-transform scale-x-100 opacity-100`};
  }

  &:hover::before {
    ${tw`transform transition-transform scale-x-100 opacity-100`};
  }
`

export const PlaceholderText = styled.span`
  ${tw`hidden nav:inline-flex relative justify-center items-center mr-3 xlnav:mr-4`}
  min-height: ${rem(68)};
  font-size: ${rem(13)};
  color: var(--color-gold-black);
`

export const PlaceholderIcon = styled(SearchIcon)``

export const InputWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ $focussed }) => ($focussed ? 'flex' : 'none')};
  /* background-color: var(--color-bg); */

  ${({ $focussed }) =>
    $focussed && {
      backgroundColor: 'var(--color-input-bg)',
    }};
`

export const Input = styled.input`
  ${tw`px-4 sm:px-6 flex-grow bg-transparent w-full`};
  color: var(--color-input-text);

  font-family: var(--font-nhu);
  font-weight: 300;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--color-input-placeholder);
  }
`

export const InputClose = styled.button`
  ${tw`flex-none flex items-center justify-center pr-6 xlnav:pr-8 top-0 right-0 h-full cursor-pointer`}/* background-color: var(--color-gold-lightest); */
`

export const CloseIcon = styled(SearchCloseIcon)``
