import { rem } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

import Text from 'components/Text'

export const Root = styled.div`
  display: grid;
  grid-template-areas:
    'title'
    'social-media'
    'divider'
    'stores';
  grid-template-rows: none;
  grid-row-gap: 2rem;

  @media (min-width: 48rem) {
    grid-template-areas:
      'title clock'
      'title social-media'
      'divider divider'
      'stores stores';
    grid-template-rows: auto 18px min-content;
  }
`

export const Title = styled(Text)`
  ${tw`pr-4`}
  grid-area: title;
  font-size: 3rem;
  line-height: 1;
  margin: -0.7rem 0 0;
  color: var(--color-dark-grey);

  @media (min-width: 36rem) {
    font-size: 3.75rem;
    margin: -1rem 0 0;
  }

  @media (min-width: 48rem) {
    font-size: 4.5rem;
    margin: -1rem 0;
  }
`

export const SmallScreenTitle = styled(Title)`
  @media (min-width: 36rem) {
    ${tw`hidden`};
  }
`

export const LargeScreenTitle = styled(Title)`
  ${tw`hidden`};

  @media (min-width: 36rem) {
    ${tw`block`};
  }
`

export const Clock = styled.div`
  grid-area: clock;
  align-self: start;
  justify-self: end;
  display: none;

  @media (min-width: 48rem) {
    display: block;
  }
`
export const SocialMedia = styled.div`
  grid-area: social-media;
  max-width: 8rem;

  @media (min-width: 48rem) {
    align-self: end;
    justify-self: end;
  }
`
export const DividerWrapper = styled.div`
  grid-area: divider;
`

export const Stores = styled.div`
  grid-area: stores;
  ${tw`grid gap-y-8 hidden`};

  @media (min-width: 36rem) {
    ${tw`grid`};
  }

  @media (min-width: ${rem(1000)}) {
    ${tw`grid-cols-2 gap-x-4`};
  }
`
