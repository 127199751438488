import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-x-8`}
  grid-template-columns: 3fr 1fr;
`

export const Main = styled.div`
  ${tw`pl-8`};
  border-left: solid 1px var(--color-grey);
`

export const Artisans = styled.div`
  ${tw`gap-4`};

  column-count: 3;
  color: var(--color-gold-black);

  @media (min-width: 1024px) {
    column-count: 4;
  }

  @media (min-width: 1280px) {
    column-count: 5;
  }
`

export const ArtisanLink = styled.div`
  ${tw`block`};
`
