/* PLOP_INJECT_IMPORT */
import useCart from './useCart'
import useClickOutside from './useClickOutside'
import useCustomer from './useCustomer'
import useRoute from './useRoute'

export {
  /* PLOP_INJECT_EXPORT */
  useCustomer,
  useCart,
  useRoute,
  useClickOutside,
}
