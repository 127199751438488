import PropTypes from 'prop-types'

import Link from 'components/Link'
import ProductCardMedium from 'components/ProductCardMedium'
import Spacer from 'components/Spacer'

import { Categories, Category, Products, Root } from './FootwearNavigation.style'

const FootwearNavigation = ({ categories, products }) => {
  return (
    <Root>
      <Categories>
        <Link text="All Footwear" href="/collections/footwear" animated weight="semibold" />
        <Spacer y={1} />
        {categories?.map(({ title, handle }, index) => (
          <Category key={index}>
            <Link text={title} href={`/collections/${handle}`} animated />
          </Category>
        ))}
      </Categories>
      <Products>
        {products?.map((product, index) => (
          <ProductCardMedium key={index} {...product} baseUrl="/collections/footwear/" />
        ))}
      </Products>
    </Root>
  )
}

FootwearNavigation.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      handle: PropTypes.string,
    })
  ),
}

FootwearNavigation.defaultProps = {}

export default FootwearNavigation
